import CustomButton from '../../reusable/CustomButton';
import selectionStepsMap from './selectionStepsConfigs';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import useUploadCardData from '../../hooks/useUploadCardData';

import './SelectionStep.css';

export interface SelectionStepProps {
  selectionStepKey: string;
  selectionStepLabel: string;
  data: string[];
  selectedData: string[];
  handleChangeStep: (stepIndex?: number) => void;
  handleSelection: (stepKey: string, isSelected: boolean, value: string[]) => void;
}

function SelectionStep({ selectionStepKey, selectionStepLabel, data, handleChangeStep, selectedData, handleSelection }: SelectionStepProps) {
  const { fileType } = useUploadCardData();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    handleSelection(selectionStepKey, event.target.checked, [value]);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSelection(selectionStepKey, event.target.checked, [...data]);
  };

  const isSelecAllIndeterminate = selectedData.length > 0 && selectedData.length !== data.length;
  const isSelectAllChecked = selectedData.length === data.length;

  return (
    <div className="upload-selection-step">
      <p className="step-description">Select the <span className="step-label">{selectionStepLabel}</span> included in this {selectionStepsMap[fileType].fileTypeName} file.</p>
      <FormGroup>
        <FormControlLabel
          label={`All ${selectionStepLabel.toLocaleLowerCase()}`}
          control={
            <Checkbox
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              indeterminate={isSelecAllIndeterminate}
            />
          }
        />
        {data.map((value, index) => (
          <FormControlLabel
            key={index}
            label={value}
            className="option"
            control={
              <Checkbox
                checked={selectedData.includes(value)}
                onChange={(e) => handleChange(e, value)}
              />
            }
          />
        ))}
      </FormGroup>
      <CustomButton
        disabled={selectedData.length === 0}
        variant="primary"
        buttonText="Next"
        size="medium"
        buttonAction={() => handleChangeStep()}
        className="step-button"
      />
    </div>
  );
}

export default SelectionStep;

