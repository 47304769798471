import SideDrawer from '../data qc/SideDrawer';
import VerticalBarChart from '../reusable/VerticalBarChart';
import CardContainer from '../data qc/CardContainer';
import DarkGreenDot from '../assets/dark-green-dot.svg';
import LightGreenDot from '../assets/light-green-dot.svg';
import Loader from '../reusable/Loader';

import './TranscriptSelectionSideDrawer.css';

interface ChartData {
  title: string;
  labels: string[];
  dataSet: {
    data: number[];
    backgroundColor: string;
  };
}

interface TranscriptSideDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  chartsData: ChartData[] | null;
  selectionChartsData: ChartData[] | null;
}

function TranscriptSideDrawer({ isOpen, handleClose, chartsData, selectionChartsData }: TranscriptSideDrawerProps) {

  return (
    <SideDrawer
      title="Demographics match"
      handleClose={handleClose}
      isOpen={isOpen}
      classes={['transcript-selection-side-drawer']}
    >
      { !chartsData ? <Loader /> : null }
      { chartsData && chartsData.map(({ title, labels, dataSet }, index) => {
        let dataSets = [dataSet];
        if (selectionChartsData) {
          dataSets = dataSets.concat(selectionChartsData[index].dataSet);
        }

        return (
          <CardContainer key={index} title={title} centerTitle={true}>
            <VerticalBarChart
              labels={labels}
              dataSets={dataSets}
              displayDataLabels={true}
              customAspectRatio={1 / 1.2}
              customCategoryPercentage={0.3}
            />
            <div className="transcript-selection-chart-legend">
              <div className="transcript-selection-chart-legend-label">
                <img alt="school demographics" src={DarkGreenDot} />
                <p>School demographics</p>
              </div>
              <div className="transcript-selection-chart-legend-label">
                <img alt="your selection" src={LightGreenDot} />
                <p>Your selection</p>
              </div>
            </div>
          </CardContainer>
        );
      })}
    </SideDrawer>
  );
}

export default TranscriptSideDrawer;