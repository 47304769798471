import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './Loader.css';

interface LoaderProps {
  fullScreen?: boolean;
}

function Loader({ fullScreen }: LoaderProps) {
  const [showLoader, setShowLoader] = useState(false);

  // avoid flashing overlay's different color background if loading takes less then 100ms
  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowLoader(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  if (fullScreen && showLoader) return (
    <>
      <div className="loader-overlay">
      </div>
      <div className="loader-container-fullscreen">
        <CircularProgress color="primary" />
      </div>
    </>
  );

  return (
    <div className="loader-container">
      <CircularProgress color="primary" />
    </div>
  );
}

export default Loader;