import { createContext, Dispatch } from 'react';
import {UploadedFileData} from '../interfaces/UploadedFileDataInterface';

interface UploadResults {
  [key: string]: UploadedFileData[];
}

interface ReplaceFileActionPayload {
  idToReplace: string;
  newFileData: UploadedFileData;
}

interface Action {
  type: string;
  payload: UploadedFileData | UploadResults | ReplaceFileActionPayload
}

interface UploadResultsContextValue {
  uploadResults: UploadResults;
  dispatch: Dispatch<Action>
}

const UploadResultsContext = createContext<UploadResultsContextValue | null>(null);

export default UploadResultsContext;
export type {
  Action,
  UploadResults,
  UploadResultsContextValue
};
