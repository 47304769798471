import CustomDivider from '../../reusable/CustomDivider';
import CustomButton from '../../reusable/CustomButton';
import useUserDetails from '../../hooks/useUserDetails';
import { postRequestUploadUrl, uploadFileToS3Bucket } from '../../../services/uploadService';
import { UploadedFileData, UploadedFileStatus } from '../../../interfaces/UploadedFileDataInterface';
import useUploadResults from '../../hooks/useUploadResults';
import { DATA_UPLOAD_REDUCER_ACTIONS } from '../dataUploadReducer';
import { AxiosError, AxiosProgressEvent } from 'axios';
import useErrorModal from '../../hooks/useErrorModal';
import useUploadCardData from '../../hooks/useUploadCardData';
import useSchools from '../../hooks/useSchools';
import { SELECTION_STEPS, selectionStepsKeysMap } from './selectionStepsConfigs';

import './ReviewSelection.css';

interface ReviewSelectionProps {
  selectionData: {
    [key: string]: string[]
  };
  categoryName: string;
  handleEdit: (stepKey: string) => void;
}
function ReviewSelection({ selectionData, categoryName, handleEdit }: ReviewSelectionProps) {
  const { data: userDetails } = useUserDetails();
  const { dispatch } = useUploadResults();
  const { handleOpen: handleOpenErrorModal } = useErrorModal();

  const { schools: { data: schools } } = useSchools();

  const { file, fileName, fileType, fileIdToReplace, handleClose, handleUploadProgress } = useUploadCardData();

  const getDataForBackend = () => {
    selectionData[SELECTION_STEPS.SCHOOL.key] = selectionData[SELECTION_STEPS.SCHOOL.key].map(schoolName => {
      let school = schools?.find(school => school.name === schoolName);
      return school?.id || '';
    });

    return selectionData;
  };

  const handleSubmit = async () => {
    let fileData: UploadedFileData = {
      file_id: '',
      file_name: fileName,
      upload_file_type: fileType,
      status: UploadedFileStatus.UPLOAD,
      statistics: [],
      results_summary: [],
      user_provided_file_metadata: {}
    };
    if (userDetails) {
      try {
        handleClose();

        let requestData = {
          file_name: fileName,
          user_provided_file_metadata: getDataForBackend(),
          upload_file_type: fileType,
          replaces_file_id: fileIdToReplace || undefined
        };
        const urlResponse = await postRequestUploadUrl(userDetails.eoaId, userDetails.access_token, requestData);
        let fileId = urlResponse.data.s3_key.split('/').at(-1) as string;

        fileData = {
          file_id: fileId,
          file_name: fileName,
          upload_file_type: fileType,
          status: UploadedFileStatus.UPLOAD,
          statistics: [],
          results_summary: [],
          user_provided_file_metadata: selectionData
        };

        if (!fileIdToReplace) {
          dispatch({
            type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA,
            payload: fileData
          });
        }
        else {
          dispatch({
            type: DATA_UPLOAD_REDUCER_ACTIONS.REPLACE_UPLOADED_FILE,
            payload: { idToReplace: fileIdToReplace, newFileData: fileData }
          });
        }

        await uploadFileToS3Bucket(urlResponse.data.presigned_url, file, handleUpload);

        fileData.status = UploadedFileStatus.PROCESSING;
        dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, payload: fileData });
      } catch (error) {
        fileData.status = UploadedFileStatus.UPLOAD_FAILED;
        dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, payload: fileData });
        handleOpenErrorModal('Error occured while uploading file!', error as AxiosError);
      }
    }
  };

  const handleUpload = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const percentsCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      handleUploadProgress(percentsCompleted);
    }
  };

  return (
    <div className="review-selection-step">
      <p>Review and edit if needed, then submit your selections.</p>
      <CustomDivider size="large" orientation="horizontal" />
      <p className="file-type-name">{categoryName}</p>
      {Object.entries(selectionData).map(([key, values]) => (
        <div key={key} className="review-selection-category">
          <div className="review-selection-category-header">
            <p>{selectionStepsKeysMap[key]}</p>
            <CustomButton variant="text" buttonText="Edit" buttonAction={() => handleEdit(key)} />
          </div>
          <ul className="category-selections">
            {values.map((value, index) => (
              <li key={index} className="selected-value">{value}</li>
            ))}
          </ul>
        </div>
      ))}
      <CustomButton
        variant="primary"
        size="medium"
        buttonText="Submit"
        buttonAction={handleSubmit}
        className="step-button"
      />
    </div>
  );
}

export default ReviewSelection;