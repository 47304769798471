import { useState } from 'react';
import ErrorModalContext from './ErrorModalContext';
import { Outlet } from 'react-router-dom';
import ErrorModal from '../components/reusable/ErrorModal';
import { AxiosError } from 'axios';

function ErrorModelContextProvider() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (errorMessage: string, error: Error | AxiosError) => {
    setErrorMessage(errorMessage);
    setIsOpen(true);
    console.error(error.message);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ErrorModalContext.Provider value={{ errorMessage, isOpen, handleOpen, handleClose }}>
      <ErrorModal />
      <Outlet />
    </ErrorModalContext.Provider>
  );
}

export default ErrorModelContextProvider;