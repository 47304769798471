import { useNavigate } from 'react-router-dom';
import { NAVIGATION_LINKS_DATA } from '../../constants';
import CardContainer from './CardContainer';
import EmptyStateImage from '../assets/data-qc-empty-state-image.svg';
import CustomButton from '../reusable/CustomButton';

import './ContentPlaceholderCard.css';

function ContentPlaceholderCard() {
  const navigate = useNavigate();

  return (
    <CardContainer noTitle={true}>
      <div className="placeholder-card-content">
        <div>
          <h2>Start uploading data</h2>
          <p>Upload data, then visualizations for each data category will appear here.</p>
          <CustomButton
            variant="primary"
            size="medium"
            buttonText="Upload data"
            buttonAction={() => navigate(NAVIGATION_LINKS_DATA.DATA_UPLOAD.path)}
          />
        </div>
        <img src={EmptyStateImage} alt="three illustrations of school"></img>
      </div>
    </CardContainer>
  );
}

export default ContentPlaceholderCard;