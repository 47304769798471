import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { darkGreenBarColor } from '../../theme/xq-custom-theme';

ChartJS.register(...registerables);

interface HorizontalBarChartProps {
  labels: string[];
  dataValues: number[];
}

function HorizontalBarChart({ labels, dataValues }: HorizontalBarChartProps) {

  const data = {
    labels: labels,
    datasets: [{
      axis: 'y',
      label: 'My First Dataset',
      data: dataValues,
      backgroundColor: darkGreenBarColor,
      borderRadius: 4,
      borderSkipped: false,
    }]
  };

  return (
    <Chart
      plugins={[ChartDataLabels]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2 / 0.9,
        layout: {
          padding: {
            right: 15
          }
        },
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: 5,
            anchor: 'end',
            color: '#000000',
            font: {
              size: 16
            }
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              color: '#000000',
            },
            ticks: {
              display: false,
              callback: (value, index) => index === 0 ? ' ' : null
            },
            border: {
              display: false
            },
            beginAtZero: true
          },
          y: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            ticks: {
              color: '#000000',
              font: {
                size: 16
              }
            }
          }
        }
      }}
      data={data}
    />
  );
}

export default HorizontalBarChart;