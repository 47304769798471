import errorIcon from '../assets/alert-error.svg';
import warningIcon from '../assets/alert-warning.svg';
import successIcon from '../assets/alert-success.svg';
import infoIcon from '../assets/alert-info.svg';

import './CustomAlert.css';
import { ReactNode } from 'react';

interface CustomAlertProps {
    alertType: 'error' | 'warning' | 'success' | 'info';
    header: string;
    children: ReactNode;
}

const alertIcons: { [key: string]: string } = {
  error: errorIcon,
  warning: warningIcon,
  success: successIcon,
  info: infoIcon,
};

function CustomAlert ({alertType, header, children} : CustomAlertProps) {

  return(
    <div data-testid="custom-alert-container" className={`custom-alert-container ${alertType}`}>
      <img alt="alert icon" src={alertIcons[alertType]} />
      <div className="custom-alert-content">
        <h3>{header}</h3>
        {children}
      </div>
    </div>
  );
};

export default CustomAlert;