import { ReactElement } from 'react';
import CustomTooltip from '../reusable/CustomTooltip';

import './CardContainer.css';

interface CardContainerProps {
  title?: string;
  tooltipTitle?: string | ReactElement<any, any> | undefined;
  centerTitle?: boolean;
  children: React.ReactNode;
  classes?: Array<string>;
  button?: React.ReactNode;
  customHeader?: React.ReactNode;
  noTitle?: boolean;
  icon?: string | null;
}

function CardContainer({title, tooltipTitle, children, classes, button, customHeader, centerTitle = false, noTitle = false, icon}: CardContainerProps) {

  const classNames = classes ? classes.join(' ') : '';

  return (
    <div className={`card-container ${classNames}`} data-testid="card-container">
      {noTitle ? 
        null
        :
        <div className={`card-container-header ${centerTitle ? 'centered' : ''}`} data-testid="card-container-header">
          {customHeader ?
            customHeader
            :
            <>
              <div className="card-container-title">
                <p>{title}</p>
                {icon ? 
                  <CustomTooltip 
                    title={tooltipTitle}
                    placement="top"
                    arrow={true}>
                    <img src={icon} alt="info icon"/>
                  </CustomTooltip>
                  : 
                  null}
              </div>
            </>
          }
          {button ? button : null}
        </div>
      }
      <div className="card-container-content" data-testid="card-container-content">
        {children}
      </div>
    </div>
  );
}

export default CardContainer;