import CustomModal from '../reusable/CustomModal';
import CustomButton from '../reusable/CustomButton';
import SuccessIcon from '../assets/data-upload-success.svg';
import XMarkIcon from '../assets/data-upload-x-mark.svg';
import WarningIcon from '../assets/data-upload-warning-amber.svg';
import { FileUploadResult, UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import CustomDivider from '../reusable/CustomDivider';

import './UploadResultsModal.css';

interface MockErrors extends FileUploadResult {
  missingColumns: string[];
}
interface UploadResultsModalProps {
  handleCloseModal: () => void;
  handleResubmit: () => void;
  results: FileUploadResult[];
  statistics: string[];
  isViewOnly: boolean;
  fileMetadata: UploadedFileData['user_provided_file_metadata'];
};

function UploadResultsModal({handleCloseModal, handleResubmit, results, statistics, fileMetadata, isViewOnly}: UploadResultsModalProps) {

  const renderStatusIcon = (status: string) => {
    switch (status) {
    case 'success':
      return SuccessIcon;
    case 'failure':
      return XMarkIcon;
    default:
      return WarningIcon;
    }
  };

  return (
    <CustomModal 
      title="Upload results" 
      isOpen={true} 
      handleClose={handleCloseModal} 
      icon={false}
    >
      <div className="upload-results-container">
        <p className="upload-results-title">File overview</p>
        <ul>
          {statistics.map((statistic, index) => <li key={index}>{statistic}</li>)}
        </ul>
        {/* fake data with errors and warnings */}
        <p className="upload-results-title">Data matching</p>
        {results.map((result, index) => (
          <div key={index} className={`result-container ${result.status}`}>
            <div className="result-status">
              <img src={renderStatusIcon(result.status)} alt="data matching status icon" />
              <p>{result.description}</p>
            </div>
            {'missingColumns' in result && (
              <ul className="missing-columns">
                {(result as MockErrors).missingColumns.map((column, idx) => (
                  <li key={idx}>{column}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
        <div className="files-metadata-section">
          <p>Your selections</p>
          <div className="files-metadata">
            {Object.entries(fileMetadata).map(([key, value]) => (
              <div key={key}>
                <h4>{key}</h4>
                <div className="file-metadata">
                  {value ?
                    value.map((val, index) => <p key={index}>{val}</p>)
                    :
                    <p>No selections available!</p>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
        <CustomDivider size="medium" orientation="horizontal" />
        {!isViewOnly &&
          <div className="custom-modal-actions-container">
            <CustomButton variant="secondary" buttonText="Replace" buttonAction={handleResubmit} />
            <CustomButton variant="primary" buttonText="Continue" buttonAction={handleCloseModal} />
          </div>
        }
      </div>
    </CustomModal>
  );
}

export default UploadResultsModal;