import { CourseCatalogsSummary } from '../../../interfaces/DataQcInterface';
import { getCourseCatalogsSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import CustomDivider from '../../reusable/CustomDivider';
import { withData } from '../../reusable/withData';

interface CourseCatalogsContentProps {
  data: CourseCatalogsSummary;
}

function CourseCatalogsContent({data}: CourseCatalogsContentProps) {

  const {total_course_count, course_counts} = data;

  return (
    <div className="course-catalogs-content" data-testid="course-catalog-list">
      <p><span>{total_course_count}</span> courses found</p>
      <CustomDivider orientation="horizontal"/>
      <div className="courses-found">
        {Object.entries(course_counts).map(([key, value]) => (
          <p key={key}><span>{value}</span> {key}</p>
        ))}
      </div>
    </div>
  );
}

const CourseCatalogsContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CourseCatalogsContent,
    fetchData: (eoaId: string, access_token: string) => getCourseCatalogsSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching course catalog summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CourseCatalogsContentWithData;