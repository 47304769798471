import ArrowBackIcon from '../assets/nav-arrow-back.svg';
import ArrowFwdIcon from '../assets/nav-arrow-fwd.svg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SideDrawerOverlay from '../reusable/SideDrawerOverlay';
import SideBarNavigationContent from './SideBarNavigationContent';
import { Dispatch, SetStateAction } from 'react';

interface SideBarNavigationProps {
  isOpen: boolean;
  handleOpen:Dispatch<SetStateAction<boolean>>;
}

const SideBarNavigation = ({isOpen, handleOpen} : SideBarNavigationProps) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleToggleMenu = () => {
    handleOpen(prev => !prev);
  };

  const handleCloseSideDrawer = () => {
    handleOpen(false);
  };

  return (
    <>
      {!isSmallScreen ?
        <SideBarNavigationContent 
          classes={['big-screen', !isOpen ? 'closed' : '']} 
          isOpen={isOpen} 
          buttonAction={handleToggleMenu} 
          src={isOpen ? ArrowBackIcon : ArrowFwdIcon}
        />
        :
        <SideDrawerOverlay isOpen={isOpen} handleClose={handleCloseSideDrawer} anchor="left">
          <SideBarNavigationContent 
            classes={['small-screen']} 
            isOpen={isOpen} 
            buttonAction={handleToggleMenu} 
            src={ArrowBackIcon}
            handleCloseSideDrawer={handleCloseSideDrawer}
          />
        </SideDrawerOverlay>
      }
    </>
  );
};

export default SideBarNavigation;
