import HorizontalBarChart from '../../reusable/HorizontalBarChart';
import { MatchingDetails } from '../../../interfaces/DataQcInterface';
import { getMatchingDetails } from '../../../services/dataQcDetailsService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import CustomDivider from '../../reusable/CustomDivider';
import MatchingStatusInfo from './MatchingStatusInfo';

import './MatchingSideDrawerContent.css';

interface MatchingSideDrawerContentProps {
  data: MatchingDetails;
}

const parseData = (data: MatchingDetails) => {

  const { demographics, students_to_test_scores, test_scores_to_students } = data;
  const { test_types, total_students_count } = students_to_test_scores;

  const chartLabels = test_types.map(({ type }) => type);
  const chartData = test_types.map(({ matched_count }) => matched_count);

  return { demographics, test_scores_to_students, total_students_count, chartData, chartLabels };
};

const sectionTitles = ['Students matched to demographics', 'Test scores matched to students'];

function MatchingSideDrawerContent({ data }: MatchingSideDrawerContentProps) {

  const { demographics, test_scores_to_students, total_students_count, chartData, chartLabels } = parseData(data);

  return (
    <>
      {[demographics, test_scores_to_students].map((dataSet, index) => (
        <div key={index} className="drawer-section">
          <p className="section-title">{sectionTitles[index]}</p>
          {dataSet.map((dataItem) => (
            <MatchingStatusInfo
              key={dataItem.type}
              label={dataItem.type}
              value={dataItem.not_matched_count > 0 ? dataItem.not_matched_count : dataItem.percentage_matched}
              description={dataItem.not_matched_count > 0 ? ' scores not matched' : ' matched'}
              isPercentage={dataItem.not_matched_count === 0}
              className="side-drawer-matching-status"
            />
          ))}
        </div>
      ))}
      <CustomDivider orientation="horizontal" />
      <div className="drawer-section chart-section">
        <p className="section-title">Students matched to test scores</p>
        <p className="chart-title">Students in cohort: {total_students_count}</p>
        {chartData ?
          <HorizontalBarChart
            dataValues={chartData}
            labels={chartLabels}
          />
          :
          <p>Error occured while loading chart data!</p>
        }
      </div>
    </>
  );
}

const MatchingSideDrawerContentWithData = () => {

  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: MatchingSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getMatchingDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching matching details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default MatchingSideDrawerContentWithData;