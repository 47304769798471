import { useContext } from 'react';
import ErrorModalContext from '../../context/ErrorModalContext';
import CustomModal from './CustomModal';
import ErrorIcon from '../assets/error-icon.svg';

import './ErrorModal.css';

function ErrorModal() {
  const { errorMessage, isOpen, handleClose } = useContext(ErrorModalContext);

  return (
    <>
      {isOpen &&
      <CustomModal title="" icon={false} isOpen={isOpen} handleClose={handleClose} maxWidth="xs">
        <div className="error-modal-container">
          <img src={ErrorIcon} alt="error"></img>
          <p className="error-modal-title">Error</p>
          <p className="error-modal-text">{errorMessage}</p>
        </div>
      </CustomModal>
      }
    </>
  );
}

export default ErrorModal;