import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getRostersUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/rosters`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getCourseCatalogUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/course-catalogs`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getStudentDemographicsUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/demographics`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getStudentTranscriptsUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/transcripts`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getTestScoresUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/test-scores`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getCTEPathwaysUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get(`${BASE_URL}/eoa/${eoaId}/upload-requirements/cte-pathways`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export {
  getRostersUploadRequirements,
  getCourseCatalogUploadRequirements,
  getStudentDemographicsUploadRequirements,
  getStudentTranscriptsUploadRequirements,
  getTestScoresUploadRequirements,
  getCTEPathwaysUploadRequirements
};