import axios from 'axios';
import { AuditLog } from '../interfaces/UploadedFileDataInterface';
import { TranscriptSelectionSummary, TranscriptDetails } from '../interfaces/TranscriptsInterface';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getTranscriptSelectionSummary = async (eoaId: string, access_token: string, school_id: string)=> {
  return await axios.get<TranscriptSelectionSummary>(`${BASE_URL}/eoa/${eoaId}/schools/${school_id}/transcripts/transcript-selection-summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
  });
};

const getTranscriptDetails = async (eoaId: string, access_token: string, school_id: string, transcript_id: string) => {

  return await axios.get<TranscriptDetails>(`${BASE_URL}/eoa/${eoaId}/schools/${school_id}/transcripts/${transcript_id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
  });
};

const finalizeTranscriptSelection = async (eoaId: string, access_token: string, school_id: string, selectedTranscripts: string[]) => {
  return await axios.post(`${BASE_URL}/eoa/${eoaId}/schools/${school_id}/transcripts/finalize-selection`,
    selectedTranscripts,
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      },
    }
  );
};

const getAuditLog = async (eoaId: string, access_token: string, page?: number, size?: number) => {

  const params = {page: page, size: size};

  return await axios.get<AuditLog>(`${BASE_URL}/eoa/${eoaId}/audit-log/data-file/`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      params
    }
  );
};

export {
  getTranscriptSelectionSummary,
  getTranscriptDetails,
  finalizeTranscriptSelection,
  getAuditLog
};