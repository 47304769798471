import axios from 'axios';
import { 
  TranscriptsSummary, 
  TestScoresSummary, 
  RostersSummary, 
  MatchingSummary, 
  DemographicsSummary, 
  CtePathwaysSummary, 
  CourseCatalogsSummary
} from '../interfaces/DataQcInterface';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getQueryParams = (school_id: string) => {
  if  (!school_id || school_id.length === 0) return {};
  
  return {
    school_id: school_id
  };
};

const getRostersSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<RostersSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/rosters/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getDemographicsSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<DemographicsSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/demographics/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getTranscriptsSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);
    
  return await axios.get<TranscriptsSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/transcripts/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getCourseCatalogsSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);
 
  return await axios.get<CourseCatalogsSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/course-catalogs/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getCtePathwaysSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<CtePathwaysSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/cte-pathways/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getMatchingSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<MatchingSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/matching/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getTestScoresSummary = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<TestScoresSummary>(`${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/summary`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

export {
  getRostersSummary,
  getDemographicsSummary,
  getTranscriptsSummary,
  getCourseCatalogsSummary,
  getCtePathwaysSummary,
  getMatchingSummary,
  getTestScoresSummary
};