import { MatchingSummary } from '../../../interfaces/DataQcInterface';
import { getMatchingSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import MatchingStatusInfo from './MatchingStatusInfo';

interface MatchingContentProps {
  data: MatchingSummary;
}

const descriptions: { [key: string]: string } = {
  students_matched_percentage: ' of students matched across demographics, transcripts, and test scores',
  transcript_courses_matched_percentage: ' of transcript courses matched to course catalog courses',
  test_scores_matched_to_students_percentage: ' of test scores matched to students',
  cte_courses_matched_percentage: ' of CTE courses matched to course catalog courses'
};

function MatchingContent({ data }: MatchingContentProps) {

  return (
    <div className="matching-content" data-testid="matching-content">
      {Object.entries(data).map(([key, value]) => (
        <MatchingStatusInfo key={key} value={value} description={descriptions[key]} className="alignment-fix" />
      ))}
    </div>
  );
}

const MatchingContentWithData = () => {

  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: MatchingContent,
    fetchData: (eoaId: string, access_token: string) => getMatchingSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching matching summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default MatchingContentWithData;