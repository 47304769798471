import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import XQTheme from './theme/xq-custom-theme';
import DataUploadWithData from './components/data upload/DataUpload';
import ProtectedRoute from './components/ProtectedRoute';
import Overview from './components/overview/Overview';
import Help from './components/help/Help';
import DataQcSummary from './components/data qc/DataQcSummary';
import MatchingContentWithData from './components/data qc/matching/MatchingContent';
import TestScoresContentWithData from './components/data qc/test scores/TestScoresContent';
import CourseCatalogsContentWithData from './components/data qc/course catalog/CourseCatalogsContent';
import TranscriptsContentWithData from './components/data qc/transcripts/TranscriptsContent';
import CteCoursesContentWithData from './components/data qc/cte courses/CteCoursesContent';
import DataQcLayout from './components/data qc/DataQcLayout';
import DataQcContentContainer from './components/data qc/DataQcContentContainer';
import MatchingSideDrawerContentWithData from './components/data qc/matching/MatchingSideDrawerContent';
import TranscriptsSideDrawerContentWithData from './components/data qc/transcripts/TranscriptsSideDrawerContent';
import TestScoresDrawerContentWithData from './components/data qc/test scores/TestScoresDrawerContent';
import CourseCatalogSideDrawerContentWithData from './components/data qc/course catalog/CourseCatalogSideDrawerContent';
import CtePathwaysSideDrawerContentWithData from './components/data qc/cte courses/CtePathwaysSideDrawerContent';
import TranscriptSelectionWithData from './components/transcripts/TranscriptSelection';
import Login from './components/login/LoginPage';
import GlobalStateContextProvider from './context/GlobalStateContextProvider';
import NavigationLayout from './components/navigation/NavigationLayout';
import ErrorModelContextProvider from './context/ErrorModelContextProvider';
import RestrictedRoute from './components/RestrictedRoute';
import { USER_ROLES, NAVIGATION_LINKS_DATA } from './constants';

const Auth0ProviderLayout = () => (
  <Auth0Provider
    domain = {process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      audience: process.env.REACT_APP_BASE_URL
    }}
  >
    <Outlet />
  </Auth0Provider>
);

const routes = [
  {path: NAVIGATION_LINKS_DATA.OVERVIEW.path, element: <Overview/>},
  {
    path: '/data-upload', 
    element: (
      <RestrictedRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.DISTRICT_DATA_LEAD, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.XQ_USER]}>
        <DataUploadWithData />
      </RestrictedRoute>
    )
  },
  {
    path: NAVIGATION_LINKS_DATA.DATA_QC.path, 
    element: (
      <RestrictedRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.DISTRICT_DATA_LEAD, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.XQ_USER]}>
        <DataQcLayout />
      </RestrictedRoute>
    ),
    children: [
      { path: '', element: <DataQcSummary /> },
      { 
        path: '/data-qc/matching', 
        element: (
          <DataQcContentContainer 
            drawerTitle="Matching summary" 
            cardTitle="Matching"
            description="This section shows how the uploaded data, including student information and transcripts, are successfully matched and connected."
            cardContent={<MatchingContentWithData />}
            drawerContent={<MatchingSideDrawerContentWithData />}
          />
        ) 
      },
      { 
        path: '/data-qc/test-scores', 
        element: (
          <DataQcContentContainer
            drawerTitle="Test scores summary"
            cardTitle="Test scores"
            description="This section shows how the uploaded data, including student information and transcripts, are successfully matched and connected."
            cardContent={<TestScoresContentWithData />}
            drawerContent={<TestScoresDrawerContentWithData />}
          />
        )
      },
      { 
        path: '/data-qc/course-catalogs', 
        element: (
          <DataQcContentContainer
            drawerTitle="Course catalogs summary"
            cardTitle="Course catalogs"
            description=""
            cardContent={<CourseCatalogsContentWithData />}
            drawerContent={<CourseCatalogSideDrawerContentWithData />}
          />
        )
      },
      { 
        path: '/data-qc/transcripts', 
        element: (
          <DataQcContentContainer
            drawerTitle="Transcripts summary"
            cardTitle="Transcripts"
            description="View matching details for uploaded data, including student information and transcripts."
            cardContent={<TranscriptsContentWithData />}
            drawerContent={<TranscriptsSideDrawerContentWithData />}
          />
        )
      },
      { 
        path: '/data-qc/cte-courses', 
        element: (
          <DataQcContentContainer
            drawerTitle="CTE courses summary"
            cardTitle="CTE courses"
            description="Description placeholder"
            cardContent={<CteCoursesContentWithData />}
            drawerContent={<CtePathwaysSideDrawerContentWithData />}
          />
        )
      },
    ]
  },
  {
    path: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path, 
    element: (
      <RestrictedRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.FACILITATOR, USER_ROLES.XQ_USER, USER_ROLES.ACCOUNT_MANAGER]}>
        <TranscriptSelectionWithData />
      </RestrictedRoute>
    )
  },
  { path: '/help', element: <Help /> },
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderLayout />}>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<ErrorModelContextProvider />}>
          <Route element={<GlobalStateContextProvider />}>
            <Route element={<NavigationLayout />}>
              {routes.map(({path, element, children}, index) => (
                <Route key={index} path={path} element={element}>
                  { children?.map(({path, element}, index) => (
                    <Route key={index} path={path} element={element} />
                  ))}
                </Route>
              ))}
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={XQTheme}>
    <RouterProvider router={router}/>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
