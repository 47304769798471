import { useReducer, Reducer, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  getRostersUploadRequirements,
  getCourseCatalogUploadRequirements,
  getStudentDemographicsUploadRequirements,
  getStudentTranscriptsUploadRequirements,
  getTestScoresUploadRequirements,
  getCTEPathwaysUploadRequirements
} from '../../services/uploadRequirementsService';
import SchoolListWithData from '../SchoolsList';
import SchoolTableWithData from '../SchoolsTable';
import UploadCard from '../reusable/UploadCard';
import { getUploadResults } from '../../services/uploadService';
import { UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import { fileTypes, UPLOAD_SECTIONS_IDS, NAVIGATION_LINKS_DATA } from '../../constants';
import UploadResultsContext from '../../context/UploadResultsContext';
import reducer from './dataUploadReducer';
import { Action, UploadResults } from '../../context/UploadResultsContext';
import PageHeader from '../reusable/PageHeader';
import { withData } from '../reusable/withData';
import AuditLogModalTable from './AuditLogModalTable';
import AuditLogTableContext from '../../context/AuditLogTableContext';
import FileTypesValues from '../../interfaces/FileType';
import WhatToUpload from '../reusable/WhatToUpload';
import './DataUpload.css';
import DataUploadSidebar from './DataUploadSidebar';

interface DataUploadProps {
  data: UploadedFileData[];
}

interface AuditLogData {
  isOpen: boolean;
  fileType: FileTypesValues | ''
}

const pageDescription = 'Upload files that contain data, such as the student roster, course catalog, and student demographics, for each school.';

const DataUpload = ({ data }: DataUploadProps) => {

  const [uploadResults, dispatch] = useReducer<Reducer<UploadResults, Action>>(reducer, distributeData(data));
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [auditLogData, setAuditLogData] = useState<AuditLogData>({ isOpen: false, fileType: '' });

  const selectedCard = cardsData.find(card => card.cardId === selectedCardId);

  const handleOpenAuditLog = (fileType: FileTypesValues | '') => {
    if (fileType === auditLogData.fileType) return;

    setAuditLogData({ isOpen: true, fileType });
  };

  const handleCloseSidebar = () => {
    setSelectedCardId(null);
  };

  return (
    <>
      <PageHeader title={NAVIGATION_LINKS_DATA.DATA_UPLOAD.label} pageDescription={pageDescription} />
      <div className="data-upload">
        <div className="main-content">
          <UploadResultsContext.Provider value={{ uploadResults, dispatch }}>
            <AuditLogTableContext.Provider value={{ handleOpenAuditLog }}>
              {cardsData.map((cardData, index) => (
                <UploadCard
                  key={index}
                  title={cardData.cardTitle}
                  helperText={cardData.cardHelperText}
                  uploadedFilesData={uploadResults[cardData.fileType]}
                  fileType={cardData.fileType}
                  cardId={cardData.cardId}
                  handleSelectedCardId={setSelectedCardId}
                  sampleFileName={cardData.sampleFileName}
                />
              ))}
            </AuditLogTableContext.Provider>
          </UploadResultsContext.Provider>
          {auditLogData.isOpen ?
            <AuditLogModalTable handleCloseModal={() => setAuditLogData({ isOpen: false, fileType: '' })} fileType={auditLogData.fileType} />
            :
            null
          }
        </div>
        {createPortal(
          selectedCard ?
            <DataUploadSidebar
              handleCloseSidebar={handleCloseSidebar}
            >
              <WhatToUpload
                cardTitle={selectedCard ? selectedCard.cardTitle : ''}
                fileTypeLabel={selectedCard ? selectedCard.fileTypeLabel : ''}
                handleCloseSidebar={handleCloseSidebar}
              >
                {selectedCard?.displayTable ?
                  <SchoolTableWithData fetchData={selectedCard.fetchData} fileType={selectedCard.fileType} />
                  :
                  <SchoolListWithData fetchData={selectedCard.fetchData} />
                }
              </WhatToUpload>
            </DataUploadSidebar>
            :
            <></>
          , document.getElementById('sideDrawer') as HTMLElement)
        }
      </div>
    </>
  );
};

const cardsData = [
  {
    cardTitle: 'Student Roster',
    cardHelperText: [
      'Please upload student roster(s) for the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure the file(s) contain at minimum Student ID and school ID if the file contains data for multiple schools.'
    ],
    sampleFileName: 'Rosters Sample File.xlsx',
    fileTypeLabel: 'Student Roster(s).xlsx',
    displayTable: false,
    fetchData: getRostersUploadRequirements,
    fileType: fileTypes.STUDENT_ROSTER,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_ROSTER]
  },
  {
    cardTitle: 'Course Catalog',
    cardHelperText: [
      'Please upload course catalogs for all academic years (2020-2024) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain at minimum course ID, course name, and number of credits per course.'
    ],
    sampleFileName: 'Course catalogs Sample File.xlsx',
    fileTypeLabel: 'Course Catalog(s).xlsx',
    displayTable: true,
    fetchData: getCourseCatalogUploadRequirements,
    fileType: fileTypes.COURSE_CATALOG,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.COURSE_CATALOG]
  },
  {
    cardTitle: 'Student Demographics',
    cardHelperText: [
      'Please upload student demographics for the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain student ID and the following demographic information: gender, race/ethnicity, and SPED, ELL, and free and reduced lunch status. Each file must contain student ID but demographic information can be uploaded in separate files.'
    ],
    sampleFileName: 'Student demographics Sample File.xlsx',
    fileTypeLabel: 'Student Demographics',
    displayTable: true,
    fetchData: getStudentDemographicsUploadRequirements,
    fileType: fileTypes.STUDENT_DEMOGRAPHICS,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_DEMOGRAPHICS]
  },
  {
    cardTitle: 'Student Transcripts',
    cardHelperText: [
      'Please upload transcripts for all students in the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain at minimum Student ID, School ID, course ID, course name, grade (number and/or letter), term, and credit earned.'
    ],
    sampleFileName: 'Transcripts Sample File.xlsx',
    fileTypeLabel: 'Transcripts',
    displayTable: false,
    fetchData: getStudentTranscriptsUploadRequirements,
    fileType: fileTypes.STUDENT_TRANSCRIPTS,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_TRANSCRIPTS]
  },
  {
    cardTitle: 'Test Scores',
    cardHelperText: [
      'Please upload test scores for all students in the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain student ID, test type, and the following test scores: SAT, ACT, AP, and/or IB. Each file must contain student ID and test type, but test scores can be uploaded in separate files.'
    ],
    sampleFileName: 'Test scores Sample File.xlsx',
    fileTypeLabel: 'Test Scores',
    displayTable: true,
    fetchData: getTestScoresUploadRequirements,
    fileType: fileTypes.TEST_SCORES,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.TEST_SCORES]
  },
  {
    cardTitle: 'CTE Pathways',
    cardHelperText: [
      'Please upload CTE pathways for all academic years (2020-2024) for all schools participating in the Educational Opportunity Audit. ',
      'Please ensure file(s) contain at minimum course ID, course name, pathway name/ID and any additional information that is needed to represent pathways within your district.'
    ],
    sampleFileName: 'CTE pathways Sample File.xlsx',
    fileTypeLabel: 'CTE Pathways',
    displayTable: true,
    fetchData: getCTEPathwaysUploadRequirements,
    fileType: fileTypes.CTE_PATHWAYS,
    cardId: UPLOAD_SECTIONS_IDS[fileTypes.CTE_PATHWAYS]
  }
];

const distributeData = (data: UploadedFileData[]) => {
  let initialState: UploadResults = {
    [fileTypes.STUDENT_ROSTER]: [],
    [fileTypes.COURSE_CATALOG]: [],
    [fileTypes.STUDENT_DEMOGRAPHICS]: [],
    [fileTypes.STUDENT_TRANSCRIPTS]: [],
    [fileTypes.TEST_SCORES]: [],
    [fileTypes.CTE_PATHWAYS]: []
  };

  data.forEach((uploadResult: UploadedFileData) => {
    initialState[uploadResult.upload_file_type].push(uploadResult);
  });

  return initialState;
};

export default withData({
  WrappedComponent: DataUpload,
  fetchData: getUploadResults,
  errorMessage: 'Error occurred while fetching upload data!'
});