import { useState } from 'react';
import useUserDetails from '../hooks/useUserDetails';
import CustomButton from '../reusable/CustomButton';
import EditIcon from '../assets/nav-edit.svg';
import CustomModal from '../reusable/CustomModal';
import SelectDropdown from '../reusable/SelectDropdown';
import { SelectChangeEvent } from '@mui/material';
import { DISTRICT_LOCAL_STORAGE_KEY } from '../../constants';

import './SelectDistrictBar.css';

function SelectedDistrictBar () {

  const { data: userDetails } = useUserDetails();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(localStorage.getItem(DISTRICT_LOCAL_STORAGE_KEY) || '');
  const [temporaryDistrict, setTemporaryDistrict] = useState('');

  const handleChangeDistrict = (event: SelectChangeEvent) => {
    setTemporaryDistrict(event.target.value);
  };

  const handleCloseModal = () => {
    setTemporaryDistrict('');
    setIsModalOpen(false);
  };
  
  const handleSaveSelectedDistrict = () => {
    if (temporaryDistrict) {
      localStorage.setItem(DISTRICT_LOCAL_STORAGE_KEY, temporaryDistrict);
      setSelectedDistrict(temporaryDistrict);
      setTemporaryDistrict('');
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <CustomModal 
        title="Choose a district" 
        isOpen={isModalOpen} 
        maxWidth="xs"
        handleClose={handleCloseModal} 
        icon={false}
        dividers={false}
      >
        <p>Please select the district you would like to work on.</p>
        <SelectDropdown 
          containerClasses={['select-district-dropdown']}
          selectedValue={temporaryDistrict}
          label="District"
          items={[{ label: 'District 1', value: 'District 1' }, { label: 'District 2', value: 'District 2' }]} 
          handleChange={handleChangeDistrict}
          isClearable={false}
          size="medium"
        />
        <div className="custom-modal-actions-container">
          <CustomButton variant="secondary" buttonText="Cancel" buttonAction={handleCloseModal} size="medium" />
          <CustomButton variant="primary" disabled={!temporaryDistrict} buttonText="Save" buttonAction={handleSaveSelectedDistrict} size="medium" />
        </div>
      </CustomModal>

      <div className="district-select-bar">
        <p className="no-margin selected-district">{selectedDistrict || userDetails?.district.name}</p>
        <CustomButton
          variant="clear"
          buttonText="Change District"
          buttonAction={() => setIsModalOpen(true)}
          sx={{ fontSize: 'var(--font-size-14)', color: 'var(--text-white)' }}
          icon={<img alt="change district icon" src={EditIcon} />}
          iconPosition="start"
        />
      </div>
    </>
  );

};

export default SelectedDistrictBar;