import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { TableRow } from '@mui/material';
import CustomButton from './CustomButton';
import SortIcon from '../assets/sort-icon.svg';
import InfoIcon from '../assets/info-icon.svg';

import './CustomTable.css';

export type TableHeaderData<T> = {
  key: keyof T;
  label: string;
  icons?: Array<'sort' | 'info'>;
}
interface CustomTableProps<T extends { [key: string]: string }> {
  tableHeaderData: TableHeaderData<T>[];
  size?: 'small' | 'medium';
  tableBodyData: T[];
}

export default function CustomTable<T extends { [key: string]: string }>({tableHeaderData, size = 'medium', tableBodyData}: CustomTableProps<T>) {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [sortBy, setSortBy] = useState<keyof T | null>(null);

  const updateSortValues = (key: keyof T) => {
    setSortOrder(sortOrder && sortOrder === 'desc' ? 'asc' : 'desc');
    setSortBy(key);
  };

  const sortData = () => {
    if (!sortBy || !sortOrder) return tableBodyData;

    return tableBodyData.sort((element1, element2) => {
      let result = (element1[sortBy]).localeCompare(element2[sortBy]);

      return sortOrder === 'desc' ? result : -result;
    });
  };

  const iconNodes = {
    'sort': (label: string, key: keyof T) => (
      <CustomButton
        key={0}
        variant="clear"
        buttonText={label}
        icon={<img src={SortIcon} alt={`sort table by ${label}`}></img>}
        iconPosition="end"
        buttonAction={() => updateSortValues(key)}
        sx={{ 
          fontWeight: '600', 
          padding: 0,
          '& .MuiButton-endIcon': {
            marginRight: 0,
            marginLeft: 'var(--scaling-spacing-4)'
          }
        }}
      />
    ),
    'info': () => <img key={1} src={InfoIcon} alt="info"></img>
  };

  return (
    <TableContainer component={Paper}>
      <Table size={size}>
        <TableHead data-testid="table-head">
          <TableRow>
            {tableHeaderData.map(({key, label, icons}, index) => (
              <TableCell key={index} align="left">
                { icons ?
                  <div className={icons && icons.length > 1 ? 'multiple-icons-cell' : ''}>
                    {icons.map((iconName) => iconNodes[iconName](label, key)) }
                  </div>
                  :
                  label
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-testid="table-body">
          { sortData().map((dataRow, rowIndex) => (
            <TableRow key={dataRow.id}>
              { tableHeaderData.map((headerCell, cellIndex) => (
                <TableCell key={`${rowIndex}-${cellIndex}`}>
                  {dataRow[headerCell.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}