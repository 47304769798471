import { useState } from 'react';
import FileIcon from '../assets/file-icon.svg';
import UploadingIcon from '../assets/data-upload-uploading.svg';
import SuccessIcon from '../assets/data-upload-success.svg';
import ErrorIcon from '../assets/data-upload-x-mark.svg';
import LoaderIcon from '../assets/data-upload-loader.svg';
import RefreshIcon from '../assets/refresh-icon.svg';
import CloseIcon from '../assets/close-icon.svg';
import WarningIcon from '../assets/data-upload-warning.svg';
import UploadResultsModal from '../data upload/UploadResultsModal';
import { FileUploadResult, UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import { UploadedFileStatus } from '../../interfaces/UploadedFileDataInterface';
import useUserDetails from '../hooks/useUserDetails';
import { USER_ROLES } from '../../constants';
import { useAuditLogTableContext } from '../hooks/useAuditLogTableContext';
import FileTypesValues from '../../interfaces/FileType';
import CustomButton from './CustomButton';
import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

import './FileUploadStatus.css';

interface FileUploadStatusProps {
  fileId: string;
  fileName: string;
  fileSize: string;
  fileStatus: keyof typeof UploadedFileStatus;
  fileMetadata: UploadedFileData['user_provided_file_metadata'];
  results: FileUploadResult[];
  statistics: string[];
  onResubmit: (value: string) => void;
  fileType: FileTypesValues;
  uploadProgress: number;
}

function FileUploadStatus({ fileId, fileName, fileSize, fileStatus, fileMetadata, results, statistics, onResubmit, fileType, uploadProgress }: FileUploadStatusProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { handleOpenAuditLog } = useAuditLogTableContext();

  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;
  const isViewOnly = userRole === USER_ROLES.ACCOUNT_MANAGER;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleResubmit = () => {
    onResubmit(fileId);
    handleCloseModal();
  };

  const renderStatusIcon = () => {
    switch (fileStatus) {
    case UploadedFileStatus.UPLOAD:
      return UploadingIcon;
    case UploadedFileStatus.SUCCESS:
    case UploadedFileStatus.DATA_ERRORS:
      return FileIcon;
    case UploadedFileStatus.MALFORMED:
    case UploadedFileStatus.UPLOAD_FAILED:
      return WarningIcon;
    default:
      return LoaderIcon;
    }
  };
  
  return (
    <div className="uploaded-file">
      <div className="file-upload-info">
        <img src={renderStatusIcon()} alt="status icon" className="upload-status-icon" />
        <div className="file-name">
          {fileName}
        </div>
        <FileStatus 
          fileStatus={fileStatus} 
          handleClick={handleOpenModal} 
          fileSize={fileSize} 
          handleReplace={handleResubmit} 
          handleOpenAuditLog={() => handleOpenAuditLog(fileType)}
          isViewOnly={isViewOnly}
        />
        {isModalOpen &&
          <UploadResultsModal
            handleCloseModal={handleCloseModal}
            handleResubmit={handleResubmit}
            results={results}
            statistics={statistics}
            fileMetadata={fileMetadata}
            isViewOnly={isViewOnly}
          />
        }
      </div>
      { fileStatus === UploadedFileStatus.PROCESSING && <LinearProgress /> }
      { fileStatus === UploadedFileStatus.UPLOAD && <LinearProgressWithLabel variant="determinate" value={uploadProgress} /> }
    </div>
  );
}

const fileStatusTextMap: { [key in UploadedFileStatus]: string } = {
  [UploadedFileStatus.UPLOAD]: 'Uploading...',
  [UploadedFileStatus.UPLOAD_FAILED]: 'Upload failed',
  [UploadedFileStatus.PROCESSING]: 'Processing data...',
  [UploadedFileStatus.SUCCESS]: 'No data errors detected',
  [UploadedFileStatus.DATA_ERRORS]: 'Data errors detected',
  [UploadedFileStatus.MALFORMED]: 'Processing failed. Contact admin for support.'
};

interface FileStatusProps {
  fileStatus: keyof typeof UploadedFileStatus;
  handleClick: () => void;
  fileSize: string;
  handleReplace: () => void;
  isViewOnly: boolean;
  handleOpenAuditLog: () => void;
}

export function FileStatus({ fileStatus, handleClick, fileSize, handleReplace, isViewOnly, handleOpenAuditLog }: FileStatusProps) {
  
  const showActionButtons = fileStatus === UploadedFileStatus.SUCCESS || fileStatus === UploadedFileStatus.DATA_ERRORS;
  const showDiscardButton = fileStatus === UploadedFileStatus.UPLOAD || fileStatus === UploadedFileStatus.UPLOAD_FAILED;
  const uploadSuccessStatusClass = fileStatus === UploadedFileStatus.SUCCESS ? 'upload-status-success' : '';
  const uploadErrorStatusClass = fileStatus === UploadedFileStatus.DATA_ERRORS
    || fileStatus === UploadedFileStatus.MALFORMED 
    || fileStatus === UploadedFileStatus.UPLOAD_FAILED 
    ? 'upload-status-error' : '';

  return (
    <>
      <div data-testid="upload-info" className={`upload-info ${uploadSuccessStatusClass} ${uploadErrorStatusClass}`}>
        <div className="status-info">
          { fileStatus === UploadedFileStatus.SUCCESS && <img src={SuccessIcon} alt="success" /> }
          { fileStatus === UploadedFileStatus.DATA_ERRORS && <img src={ErrorIcon} alt="error" /> }
          <p>{fileStatusTextMap[fileStatus]}</p>
        </div>
        { showActionButtons &&
          <>
            <CustomButton
              buttonText="Review data"
              buttonAction={handleClick}
              variant="text-underlined"
              sx={{ fontSize: 'var(--font-size-14)', padding: 0, minWidth: 0 }}
            />
            { isViewOnly ?
              <CustomButton
                buttonText="Review audit log"
                buttonAction={handleOpenAuditLog}
                variant="text-underlined"
                sx={{ fontSize: 'var(--font-size-14)', padding: 0, minWidth: 0 }}
              />
              :
              <CustomButton
                buttonText="Replace"
                buttonAction={handleReplace}
                variant="text-underlined"
                sx={{ fontSize: 'var(--font-size-14)', padding: 0, minWidth: 0 }}
              />
            }
          </>
        }
        <div className="upload-actions">
          { fileStatus === UploadedFileStatus.UPLOAD_FAILED &&
            <CustomButton
              variant="clear"
              buttonAction={() => { }}
              sx={{ padding: 0, minWidth: 0 }}
            >
              <img src={RefreshIcon} alt="retry" />
            </CustomButton>
          }
          { showDiscardButton &&
            <CustomButton
              variant="clear"
              buttonAction={() => { }}
              sx={{ padding: 0, minWidth: 0 }}
            >
              <img src={CloseIcon} alt="discard" />
            </CustomButton>
          }
        </div>
        { fileSize && <p className="file-size">{fileSize}MB</p> }
      </div>
    </>
  );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'var(--text-gray)' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default FileUploadStatus;