import { TestScoresSummary } from '../../../interfaces/DataQcInterface';
import { getTestScoresSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import CustomDivider from '../../reusable/CustomDivider';
import { withData } from '../../reusable/withData';

import './TestScoresContent.css';

interface TestScoresContentProps {
  data: TestScoresSummary;
}

const descriptions: { [key: string]: string } = {
  sat_act_score_count: ' SAT/ACT superscores',
  sat_score_count: ' SAT superscores',
  act_score_count: ' ACT superscores',
  ap_score_count: ' AP testcores',
  ib_score_count: ' IB testcores'
};

function TestScoresContent({data}: TestScoresContentProps) {
  
  const testScoresSummary = data;

  return (
    <div className="test-scores-content">
      <p className="test-scores-section-title">EOA Cohort</p>
      <div data-testid="cohort-students-test-scores" className="test-scores-test-types">
        {Object.entries(testScoresSummary.cohort).map(([key, value]) => (
          <p key={key}><span>{value}</span>{descriptions[key]}</p>
        ))}
      </div>
      <CustomDivider orientation="horizontal"/>
      <p className="test-scores-section-title">All students</p>
      <div data-testid="all-students-test-scores" className="test-scores-test-types">
        {Object.entries(testScoresSummary.total).map(([key, value]) => (
          <p key={key}><span>{value}</span>{descriptions[key]}</p>
        ))}
      </div>
    </div>
  );
}

const TestScoresContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TestScoresContent,
    fetchData: (eoaId: string, access_token: string) => getTestScoresSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching test scores summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TestScoresContentWithData;