import CheckMarkIcon from '../../assets/data-upload-success.svg';
import ErrorIcon from '../../assets/data-upload-x-mark.svg';

import './MatchingStatusInfo.css';

interface MatchingStatusInfoProps {
  value: number;
  description: string;
  isPercentage?: boolean;
  className?: string;
  label?: string;
}
function MatchingStatusInfo({ value, description, isPercentage = true, className = '', label }: MatchingStatusInfoProps) {

  //to be refactored, when thresholds are defined
  const isError = !isPercentage || value <= 80;

  return (
    <div className={`matching-status-info ${className}`}>
      {label ? <p className="no-margin status-info-label">{label}</p> : null}
      <img className="icon-size" src={isError ? ErrorIcon : CheckMarkIcon} alt="matching status" />
      <p className={`no-margin ${isError ? 'matching-error' : ''} info-text`}>
        <strong>{value}{isPercentage ? '%' : ''}</strong>
        {description}
      </p>
    </div>
  );
}

export default MatchingStatusInfo;