import { RostersSummary } from '../../interfaces/DataQcInterface';
import { getRostersSummary } from '../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../hooks/useDataQcOutletContext';
import { withData } from '../reusable/withData';
import DataQcCardContentWithTable from './DataQcCardContentWithTable';
import { STUDENT_ROSTER_ITEMS_PER_PAGE } from '../../constants';
import { useState } from 'react';

const columns = [
  {key: 'name', className: ''},
  {key: 'student_count', className: 'align-right'}
];

interface RosterContentProps {
  data: RostersSummary;
}

export function RosterContent({data}: RosterContentProps) {

  const {selectedSchoolFromDataQc} = useDataQcOutletContext();

  const {total_student_count, schools} = data;

  //only for test purposes
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="roster-content">  
      <div className="students-schools-count">
        <p data-testid="students-info"><span>{total_student_count}</span> students</p>
        {!selectedSchoolFromDataQc &&  <p data-testid="schools-info"><span>{schools.length}</span> schools</p>}
      </div>
      {!selectedSchoolFromDataQc &&
        <DataQcCardContentWithTable 
          schools={schools}
          columns={columns}
          currentPage={currentPage}
          handlePageChange={setCurrentPage}
          totalItems={100} 
          pageSize={STUDENT_ROSTER_ITEMS_PER_PAGE}/>
      }
    </div>
  );
}

const RosterContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: RosterContent,
    fetchData: (eoaId: string, access_token: string) => getRostersSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching rosters summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default RosterContentWithData;