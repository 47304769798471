import Button from '@mui/material/Button';
import { SxProps, ButtonOwnProps } from '@mui/material';
import { ReactNode, ElementType } from 'react';

type CustomVariants = 'primary' | 'secondary' | 'text' | 'text-underlined' | 'clear';
interface CustomButtonProps {
  buttonText?: string | ReactNode;
  buttonAction: () => void;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  size?: 'small' | 'medium';
  disabled?: boolean;
  sx?: SxProps;
  variant: CustomVariants;
  children?: ReactNode;
  buttonRef?: React.Ref<HTMLButtonElement>;
  className?: string;
  href?: string;
  linkComponent?: ElementType<any>;
}

type CustomVariantsMap = {
  [key in CustomVariants]: ButtonOwnProps['variant']
};

export default function CustomButton({ 
  buttonText, 
  buttonAction, 
  icon, 
  iconPosition, 
  size, 
  disabled, 
  sx, 
  variant, 
  children, 
  buttonRef, 
  className,
  href,
  linkComponent
}: CustomButtonProps) {

  const customVariantsMap: CustomVariantsMap = {
    'primary': 'contained',
    'secondary': 'outlined',
    'text': 'text',
    'text-underlined': 'text-underlined',
    'clear': 'clear'
  };

  return (
    <Button
      onClick={buttonAction}
      variant={customVariantsMap[variant]}
      startIcon={iconPosition === 'start' ? icon : null}
      endIcon={iconPosition === 'end' ? icon : null}
      size={size || 'small'}
      disabled={disabled}
      sx={sx}
      ref={buttonRef}
      className={className}
      href={href}
      LinkComponent={linkComponent}
    >
      {children ? children : buttonText}
    </Button>
  );
}
