import { Navigate } from 'react-router-dom';
import useUserDetails from './hooks/useUserDetails';

interface RestrictedRouteProps {
    children: React.ReactElement;
    allowedRoles: string[];
    };

const RestrictedRoute = ({ children, allowedRoles }: RestrictedRouteProps) => {
  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;

  if (userRole && !allowedRoles.includes(userRole)) {
    return <Navigate to="/overview" />;
  }

  return children;
};

export default RestrictedRoute;
