import { useState, useRef } from 'react';
import useUserDetails from '../hooks/useUserDetails';
import Loader from '../reusable/Loader';
import ArrowDown from '../assets/arrow-down.svg';
import ArrowUp from '../assets/arrow-up.svg';
import UserProfilePopover from './UserProfilePopover';
import CustomButton from '../reusable/CustomButton';

import './UserProfile.css';

function UserProfile() {
  const { isPending, error, data: userDetails } = useUserDetails();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const anchorElement = useRef<HTMLButtonElement>(null);

  if (isPending) return (
    <div>
      <Loader />
    </div>
  );

  const handleClickUserProfile = () => {
    setIsProfileMenuOpen(true);
  };

  return (
    <>
      <CustomButton 
        className="user-profile-container"
        data-testid="user-profile-container"
        buttonAction={handleClickUserProfile} 
        variant="clear"
        buttonRef={anchorElement}
        icon={isProfileMenuOpen ? <img src={ArrowUp} alt="expand more"></img> : <img src={ArrowDown} alt="expand less"></img>}
        iconPosition="end"
        sx={{ padding: 0 }}
      >
        <div>
          { error || !userDetails ?
            <p>Missing user data!</p>
            :
            <>
              <p className="user-name">{userDetails.name}</p>
              <p className="user-role">{userDetails.roleDescription}</p>
            </>
          }
        </div>
      </CustomButton>
      {isProfileMenuOpen &&
        <UserProfilePopover
          anchorEl={anchorElement.current}
          handleIsProfileMenuOpen={setIsProfileMenuOpen}
        />
      }
    </>
  );
}

export default UserProfile;