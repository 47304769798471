import {UploadedFileData} from '../../interfaces/UploadedFileDataInterface';

const DATA_UPLOAD_REDUCER_ACTIONS = Object.freeze({
  UPDATE_UPLOADED_FILES_DATA: 'UPDATE_UPLOADED_FILES_DATA',
  SET_UPLOAD_RESULTS: 'SET_UPLOAD_RESULTS',
  REPLACE_UPLOADED_FILE: 'REPLACE_UPLOADED_FILE'
});

const reducer = (state: any, action: any) => {
  switch (action.type) {
  case DATA_UPLOAD_REDUCER_ACTIONS.SET_UPLOAD_RESULTS: 
    return {...action.payload};
  case DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA:
    let existingFileIndex = state[action.payload.upload_file_type].findIndex((file: UploadedFileData) => file.file_id === action.payload.file_id);
    if (existingFileIndex > -1) {
      state[action.payload.upload_file_type][existingFileIndex] = { ...action.payload };
    } else {
      state[action.payload.upload_file_type].push(action.payload);
    }
    return {
      ...state,
      [action.payload.upload_file_type]: [...state[action.payload.upload_file_type]]
    };
  case DATA_UPLOAD_REDUCER_ACTIONS.REPLACE_UPLOADED_FILE:
    let { idToReplace, newFileData } = action.payload;
    let fileToReplaceIndex = state[newFileData.upload_file_type].findIndex((file: UploadedFileData) => file.file_id === idToReplace);
    state[newFileData.upload_file_type][fileToReplaceIndex] = { ...newFileData };
    return {
      ...state,
      [newFileData.upload_file_type]: [...state[newFileData.upload_file_type]]
    };
  }
};

export default reducer;
export {
  DATA_UPLOAD_REDUCER_ACTIONS
};