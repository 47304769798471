import { ChartCategoryTypes, Transcript } from '../../interfaces/TranscriptsInterface';

const chartCategories = [
  ChartCategoryTypes.RACE,
  ChartCategoryTypes.GENDER,
  ChartCategoryTypes.DISABILITY_STATUS,
  ChartCategoryTypes.ENGLISH_LEARNER,
  ChartCategoryTypes.LOW_INCOME
];

export const getCategoryCountsByType = (data: Transcript[]) => {
  const categoryCountsByType: { [key in ChartCategoryTypes]: { [key: string]: number } } = {
    [ChartCategoryTypes.RACE]: {},
    [ChartCategoryTypes.GENDER]: {},
    [ChartCategoryTypes.DISABILITY_STATUS]: {},
    [ChartCategoryTypes.ENGLISH_LEARNER]: {},
    [ChartCategoryTypes.LOW_INCOME]: {}
  };

  data.forEach(dataItem => {
    chartCategories.forEach((chartCategory) => {
      let currentCategoryType = categoryCountsByType[chartCategory];
      let categoryKey = dataItem[chartCategory];

      if (currentCategoryType.hasOwnProperty(categoryKey)) {
        currentCategoryType[categoryKey]++;
      } else {
        currentCategoryType[categoryKey] = 1;
      }
    });
  });

  return categoryCountsByType;
};

export const extractChartData = (categoryData: { [key: string]: number }) => {
  const labels: string[] = [];
  const data: number[] = [];

  Object.entries(categoryData).forEach(([key, value]) => {
    labels.push(key);
    data.push(value);
  });

  return { labels, data };
};

type ParsedData = { [key in ChartCategoryTypes]: { [key: string]: number } };

export const buildChartData = (parsedData: ParsedData, chartCategories: ChartCategoryTypes[], barColor: string) => {
  let combinedLabels: string[] = [];
  let combinedData: number[] = [];

  chartCategories.forEach(chartCategory => {
    let { labels, data } = extractChartData(parsedData[chartCategory]);
    combinedLabels = combinedLabels.concat(labels);
    combinedData = combinedData.concat(data);
  });

  return { labels: combinedLabels, dataSet: { data: combinedData, backgroundColor: barColor } };
};

export const createChartConfigs = (data: Transcript[], barColor: string) => {
  let parsedData = getCategoryCountsByType(data);
  let raceChartData = buildChartData(parsedData, [ChartCategoryTypes.RACE], barColor);
  let genderChartData = buildChartData(parsedData, [ChartCategoryTypes.GENDER], barColor);
  let miscChartData = buildChartData(
    parsedData,
    [
      ChartCategoryTypes.DISABILITY_STATUS,
      ChartCategoryTypes.ENGLISH_LEARNER,
      ChartCategoryTypes.LOW_INCOME
    ],
    barColor
  );

  let raceChartConfig = {
    title: 'Race/Ethnicity',
    labels: raceChartData.labels,
    dataSet: raceChartData.dataSet
  };

  let genderChartConfig = {
    title: 'Gender',
    labels: genderChartData.labels,
    dataSet: genderChartData.dataSet
  };

  let miscChartConfig = {
    title: 'SWD, EL, LI',
    labels: miscChartData.labels,
    dataSet: miscChartData.dataSet
  };

  return [raceChartConfig, genderChartConfig, miscChartConfig];
};