import { Link, useNavigate } from 'react-router-dom';
import CardContainer from './CardContainer';
import RosterContentWithData from './RosterContent';
import DemographicsContentWithData from './demographics/DemographicsContent';
import TranscriptsContentWithData from './transcripts/TranscriptsContent';
import CteCoursesContentWithData from './cte courses/CteCoursesContent';
import MatchingContentWithData from './matching/MatchingContent';
import TestScoresContentWithData from './test scores/TestScoresContent';
import CourseCatalogsContentWithData from './course catalog/CourseCatalogsContent';
import useUserDetails from '../hooks/useUserDetails';
import { USER_ROLES } from '../../constants';
import OpenModalIcon from '../assets/data-upload-arrow-right.svg';
import InfoIcon from '../assets/info-icon.svg';
import CustomAlert from '../reusable/CustomAlert';
import ContentPlaceholderCard from './ContentPlaceholderCard';

import './DataQcSummary.css';
import CustomButton from '../reusable/CustomButton';

const cards = [
  {
    title: 'Roster: EOA cohort',
    component: <RosterContentWithData />,
    path: '',
    shouldRenderViewMore: false,
    infoIcon: InfoIcon,
    tooltipTitle: 'This table displays the number of students with complete data from each of the last four years.'
  },
  { title: 'Test scores', component: <TestScoresContentWithData />, path: '/data-qc/test-scores', shouldRenderViewMore: true, infoIcon: InfoIcon },
  {
    title: 'Transcripts: EOA cohort',
    component: <TranscriptsContentWithData />,
    path: '/data-qc/transcripts',
    shouldRenderViewMore: true,
    infoIcon: InfoIcon,
    tooltipTitle: 'This table displays the number of student transcripts with complete data from each of the last four years.'
  },
  { title: 'CTE courses', component: <CteCoursesContentWithData />, path: '/data-qc/cte-courses', shouldRenderViewMore: true },
  { title: 'Matching', component: <MatchingContentWithData />, path: '/data-qc/matching', shouldRenderViewMore: true, infoIcon: InfoIcon },
  { title: 'Course catalogs', component: <CourseCatalogsContentWithData />, path: '/data-qc/course-catalogs', shouldRenderViewMore: true },
];

function DataQcSummary() {
  const navigate = useNavigate();
  const { data: userDetails } = useUserDetails();

  const handleOpen = (path: string) => {
    if (path) navigate(path);
  };

  const renderViewMoreButton = (shouldRenderViewMore: boolean, path: string) => {
    if (shouldRenderViewMore) {
      return (
        <CustomButton
          variant="text" 
          buttonText="View more"
          buttonAction={() => handleOpen(path)} 
          icon={<img src={OpenModalIcon} alt="open data qc summary details view"></img>}
          iconPosition="end"
          sx={{'& .MuiButton-endIcon': { marginLeft: 'var(--scaling-spacing-4)'}}}
        />
      );
    }
  };

  //for refactor when conditions for showing proper alert are clear
  const alertType = 'warning';
  const header = 'Missing information?';

  return (
    <div className="data-qc-main-content main-content">
      {userDetails && userDetails.role !== USER_ROLES.XQ_USER ?
        <CustomAlert alertType={alertType} header={header}>
          <div className="data-qc-info-container">
            <p className="no-margin">
              You can upload additional data and edit files on&nbsp;
              <Link className="link" to="/data-upload">the data upload page. </Link>
              &nbsp;For more information,&nbsp;
              <Link className="link" to="mailto:yourmail@domain.com">contact your account manager</Link>.
            </p>
          </div>
        </CustomAlert>
        :
        null
      }
      <ContentPlaceholderCard />
      <CardContainer title="Demographics">
        <DemographicsContentWithData />
      </CardContainer>
      <div className="data-qc-cards-container">
        {cards.map(({ title, component, path, shouldRenderViewMore, infoIcon, tooltipTitle }, index) => (
          <CardContainer
            key={index}
            title={title}
            tooltipTitle={tooltipTitle}
            button={renderViewMoreButton(shouldRenderViewMore, path)}
            classes={['data-qc-summary-card']}
            icon={infoIcon}>
            {component}
          </CardContainer>
        ))}
      </div>
    </div>
  );
}

export default DataQcSummary;