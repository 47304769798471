import { AxiosResponse } from 'axios';
import { getAcademicYears, getDemographicCategories } from '../../../services/uploadService';
import { fileTypes } from '../../../constants';
import FileTypesValues from '../../../interfaces/FileType';

export const SELECTION_STEPS = Object.freeze({
  SCHOOL: {
    key: 'school_ids',
    label: 'Schools',
    title: 'Select schools'
  },
  ACADEMIC_PERIOD: {
    key: 'academic_periods',
    label: 'Academic years',
    title: 'Select academic years'
  },
  DEMOGRAPHIC_TYPES: {
    key: 'demographic_types',
    label: 'Demographic Types',
    title: 'Select demographic types'
  },
  TEST_TYPES: {
    key: 'test_types',
    label: 'Test Types',
    title: 'Select test types'
  },
  REVIEW: {
    key: 'review',
    label: 'Review selections',
    title: 'Review selections'
  },
  ERROR: {
    key: 'error',
    label: '',
    title: 'Something went wrong'
  }
});

export const selectionStepsKeysMap = Object.freeze({
  [SELECTION_STEPS.SCHOOL.key]: SELECTION_STEPS.SCHOOL.label,
  [SELECTION_STEPS.ACADEMIC_PERIOD.key]: SELECTION_STEPS.ACADEMIC_PERIOD.label,
  [SELECTION_STEPS.DEMOGRAPHIC_TYPES.key]: SELECTION_STEPS.DEMOGRAPHIC_TYPES.label,
  [SELECTION_STEPS.TEST_TYPES.key]: SELECTION_STEPS.TEST_TYPES.label,
});

export type SelectionStepKey = typeof SELECTION_STEPS.SCHOOL.key
  | typeof SELECTION_STEPS.ACADEMIC_PERIOD.key
  | typeof SELECTION_STEPS.DEMOGRAPHIC_TYPES.key
  | typeof SELECTION_STEPS.TEST_TYPES.key;

type SelectionStepLabel = typeof SELECTION_STEPS.SCHOOL.label
  | typeof SELECTION_STEPS.ACADEMIC_PERIOD.label
  | typeof SELECTION_STEPS.DEMOGRAPHIC_TYPES.label
  | typeof SELECTION_STEPS.TEST_TYPES.label;

type SelectionStepTitle = typeof SELECTION_STEPS.SCHOOL.title
  | typeof SELECTION_STEPS.ACADEMIC_PERIOD.title
  | typeof SELECTION_STEPS.DEMOGRAPHIC_TYPES.title
  | typeof SELECTION_STEPS.TEST_TYPES.title;

interface SelectionStepConfig {
  selectionSteps: {
    key: SelectionStepKey;
    label: SelectionStepLabel,
    title: SelectionStepTitle
  }[];
  fileTypeName: string;
}

export interface SelectionStepExtendedConfig extends SelectionStepConfig {
  errorMessage: string;
  fetchData: (eoaId: string, access_token: string) => Promise<AxiosResponse<string[]>>;
}

type SelectionStepsMap = {
  [key in FileTypesValues]: SelectionStepConfig | SelectionStepExtendedConfig;
}
const selectionStepsMap: SelectionStepsMap = {
  [fileTypes.STUDENT_ROSTER]: {
    selectionSteps: [SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'student roster'
  },
  [fileTypes.COURSE_CATALOG]: {
    selectionSteps: [SELECTION_STEPS.ACADEMIC_PERIOD, SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'course catalog',
    errorMessage: 'Missing academic periods data!',
    fetchData: getAcademicYears
  },
  [fileTypes.STUDENT_DEMOGRAPHICS]: {
    selectionSteps: [SELECTION_STEPS.DEMOGRAPHIC_TYPES, SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'student demographics',
    errorMessage: 'Missing demographic types data!',
    fetchData: getDemographicCategories
  },
  [fileTypes.STUDENT_TRANSCRIPTS]: {
    selectionSteps: [SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'student transcripts'
  },
  [fileTypes.TEST_SCORES]: {
    selectionSteps: [SELECTION_STEPS.TEST_TYPES, SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'test scores',
    errorMessage: 'Missing test types data!'
  },
  [fileTypes.CTE_PATHWAYS]: {
    selectionSteps: [SELECTION_STEPS.ACADEMIC_PERIOD, SELECTION_STEPS.SCHOOL, SELECTION_STEPS.REVIEW, SELECTION_STEPS.ERROR],
    fileTypeName: 'CTE pathways',
    errorMessage: 'Missing academic periods data!',
    fetchData: getAcademicYears
  }
};

export const TEST_TYPES = ['SAT', 'ACT', 'IB', 'AP'];

export default selectionStepsMap;