import { ReactNode } from 'react';
import SideDrawerOverlay from '../reusable/SideDrawerOverlay';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import './DataUploadSidebar.css';

interface DataUploadSidebarProps {
  children: ReactNode;
  handleCloseSidebar: () => void;
}

function DataUploadSidebar ({ children, handleCloseSidebar }: DataUploadSidebarProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen) {
    return (
      <SideDrawerOverlay 
        isOpen={true} 
        handleClose={(handleCloseSidebar)} 
        anchor="right"
        className="data-upload-sidebar"
      >
        {children}
      </SideDrawerOverlay>
    );
  }
  return (
    <div className="data-upload-sidebar">
      {children}
    </div>
  );
};

export default DataUploadSidebar;
