import { createContext } from 'react';
import { Transcript } from '../interfaces/TranscriptsInterface';

interface TranscriptSelectionContextValue {
  selectedTranscripts: Transcript[];
  handleSelectTranscripts: (newTranscriptSelection: Transcript[]) => void;
}

const TranscriptSelectionContext = createContext<TranscriptSelectionContextValue>({
  selectedTranscripts: [],
  handleSelectTranscripts: () => {}
});

export default TranscriptSelectionContext;