import { Dialog, DialogTitle, DialogContent, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '../assets/qustion-mark-icon.svg';
import CustomButton from './CustomButton';

import './CustomModal.css';
interface CustomModalProps {
  title?: string;
  titleComponent?: React.ReactNode;
  children: any;
  isOpen: boolean;
  handleClose: () => void;
  icon: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sx?: SxProps;
  dividers?: boolean;
}

function CustomModal({ title, children, isOpen, handleClose, icon, maxWidth = 'sm', sx = {}, dividers, titleComponent }: CustomModalProps) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={true}
      sx={sx}
    >
      <div className="dialog-title-container">
        { title ? 
          <DialogTitle id="customized-dialog-title">
            {title}
          </DialogTitle>
          : 
          <>
            {titleComponent}
          </>
        }
        { icon && <img className="pointer" alt="question mark icon" src={QuestionMarkIcon}/> }
        <CustomButton
          variant="clear"
          buttonAction={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </CustomButton>
      </div>
      <DialogContent dividers={dividers}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default CustomModal;