import NewWindowIcon from '../assets/footer-new-window.svg';
import XQLogo from '../assets/footer-xq-logo.svg';
import CustomButton from '../reusable/CustomButton';

import './Footer.css';

const footerLinks = [
  { link: 'Privacy Policy', icon: NewWindowIcon },
  { link: 'Terms & conditions', icon: NewWindowIcon }
];

interface FooterProps {
  classNameProp?: string;
}

function Footer({ classNameProp = '' }: FooterProps) {

  return (
    <footer data-testid="footer" className={`footer ${classNameProp}`}>
      <div className="footer-links-container">
        <img alt="xq footer logo" src={XQLogo} />
        {footerLinks.map(({ link, icon }) => (
          <div key={link} className="footer-link-container">
            {/* this should be changed to a link when we get a real link for href attribute */}
            {/* add a footer-link classname to the anchor element for correct styles */}
            {/* is it a button for now because otherwise the eslint error prevets us from deploying */}
            <CustomButton
              variant="clear"
              buttonText={link}
              buttonAction={() => { }}
              icon={<img alt="open in new window icon" src={icon} />}
              iconPosition="end"
              sx={{ color: 'var(--text-link)', textDecoration: 'underline', fontWeight: 'var(--font-weight-700)' }}
            />
          </div>
        ))}
      </div>
      <p className="copyright">Copyright © 2024 XQ Institute. All rights reserved.</p>
    </footer>
  );
}

export default Footer;