import { FormControl, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import './SelectDropdown.css';

type FormControlSize = 'small' | 'medium';

interface SelectDropdownProps {
  label: string;
  selectedValue: string;
  handleChange: (event: any) => void;
  handleDelete?: () => void;
  items: {
    label: string,
    value: string
  }[];
  containerClasses?: string[];
  formControlClasses?: string[];
  isClearable?: boolean;
  size?: FormControlSize;
  selectSxProps?: SxProps;
  fullWidth?: boolean;
}

function SelectDropdown({
  label, 
  selectedValue, 
  handleChange, 
  handleDelete, 
  items, 
  containerClasses, 
  formControlClasses,
  isClearable = true,
  size,
  selectSxProps,
  fullWidth = true
}: SelectDropdownProps) {

  const containerClassNames = containerClasses ? containerClasses.join(' ') : '';
  const formControlClassNames = formControlClasses ? formControlClasses.join(' ') : '';

  return (
    <div className={`${containerClassNames}`} data-testid="form-control-container">
      <FormControl 
        variant="standard"
        className={`${formControlClassNames} select`} 
        size={size} 
        data-testid="form-control" 
        fullWidth={fullWidth}
        sx={{ minWidth: '200px' }}
      >
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          sx={selectSxProps}
          labelId="select-label"
          label={label}
          value={selectedValue}
          onChange={handleChange}
        >
          { items?.map((item, index) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectDropdown;