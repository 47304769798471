import { Transcript, Category, TranscriptSelectionSummary } from '../../interfaces/TranscriptsInterface';
import { getTranscriptSelectionSummary } from '../../services/transcriptsService';
import { useMemo, useState, useRef } from 'react';
import useSchools from '../hooks/useSchools';
import { darkGreenBarColor, lightGreenBarColor } from '../../theme/xq-custom-theme';
import { createChartConfigs } from './utilityFunctions';
import TranscriptSelectionHeader from './TranscriptSelectionHeader';
import TranscriptSelectionTables from './TranscriptSelectionTables';
import TranscriptSelectionSideDrawer from './TranscriptSelectionSideDrawer';
import TranscriptSelectionWarningModal from './TranscriptSelectionWarningModal';
import TranscriptSelectionContext from '../../context/TranscriptSelectionContext';
import { USER_ROLES, HIGH_FLIERS_SELECTION_LIIMIT, ON_THE_CUSP_SELECTION_LIIMIT, STRUGGLING_SELECTION_LIIMIT } from '../../constants';
import useUserDetails from '../hooks/useUserDetails';

import './TranscriptSelection.css';
import { withData } from '../reusable/withData';

interface TranscriptSelectionProps {
  data: TranscriptSelectionSummary;
}

const categories: Category[] = [
  { name: 'HIGH_FLIER', label: 'High fliers' },
  { name: 'ON_THE_CUSP', label: 'On the cusp' },
  { name: 'STRUGGLING', label: 'Struggling' }
];

const selectionLimits = [HIGH_FLIERS_SELECTION_LIIMIT, ON_THE_CUSP_SELECTION_LIIMIT, STRUGGLING_SELECTION_LIIMIT];

function TranscriptSelection({data}: TranscriptSelectionProps) {
  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;
  const isRestrictedView = userRole === USER_ROLES.XQ_USER || userRole === USER_ROLES.ACCOUNT_MANAGER;

  const { selectedSchoolFromSideNav } = useSchools();

  const [refreshData, setRefreshData] = useState(false);

  const [selectedTranscripts, setSelectedTranscripts] = useState<Transcript[]>([]);

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const memoizedCategories = useMemo(() => {
    if (!data) return null;

    setIsSideDrawerOpen(data.is_finalized);
    
    return categories.map(({ name, label }, index) => {
      const filteredData = data.transcripts.filter(transcript => transcript.category === name);
      return {
        name,
        label,
        filteredData,
        selectionLimit: selectionLimits[index]
      };
    });
  }, [data]);

  const memoizedDataForCharts = useMemo(() => {
    if (!data) return null;

    return createChartConfigs(data.transcripts, darkGreenBarColor);
  }, [data]);

  const memoizedSelectionChartData = useMemo(() => {
    if (!selectedTranscripts.length) return null;

    return createChartConfigs(selectedTranscripts, lightGreenBarColor);
  }, [selectedTranscripts]);

  const temporarySelection = useRef<Transcript[]>([]);

  const handleSelectTranscripts = (newTranscriptSelection: Transcript[]) => {
    if (data.is_finalized) {
      temporarySelection.current = newTranscriptSelection;
      setIsModalOpen(true);
    } else {
      setSelectedTranscripts(newTranscriptSelection);
    }
  };

  const handleCancelSelectionChange = () => {
    temporarySelection.current = [];
    setIsModalOpen(false);
  };

  const handleConfirmSelectionChange = () => {
    handleSelectTranscripts(temporarySelection.current);
    temporarySelection.current = [];
    setIsModalOpen(false);
  };

  return (
    <TranscriptSelectionContext.Provider value={{ selectedTranscripts, handleSelectTranscripts }}>
      <TranscriptSelectionHeader 
        isFinalized={data.is_finalized} 
        selectedSchool={selectedSchoolFromSideNav}
        triggerRefresh={() => setRefreshData(!refreshData)}
        handleViewSchoolDemographics={() => setIsSideDrawerOpen(!isSideDrawerOpen)}
        isRestrictedView={isRestrictedView}
      />
      <div className="transcript-selection-tables-charts-container main-content">
        <TranscriptSelectionTables 
          isFinalized={data.is_finalized}
          categories={memoizedCategories}
          isRestrictedView={isRestrictedView}
        />
        <TranscriptSelectionSideDrawer 
          isOpen={isSideDrawerOpen}
          handleClose={() => setIsSideDrawerOpen(false)}
          chartsData={memoizedDataForCharts}
          selectionChartsData={memoizedSelectionChartData}
        />
      </div>
      <TranscriptSelectionWarningModal 
        isOpen={isModalOpen}
        handleConfirm={handleConfirmSelectionChange}
        handleCancel={handleCancelSelectionChange}
      />
    </TranscriptSelectionContext.Provider>
  );
}

const TranscriptSelectionWithData = () => {
  
  const { selectedSchoolFromSideNav } = useSchools();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptSelection,
    fetchData: (eoaId: string, access_token: string) => getTranscriptSelectionSummary(eoaId, access_token, selectedSchoolFromSideNav),
    errorMessage: 'Error occurred while fetching transcript selection summary data!',
    options: {
      dependencies: [selectedSchoolFromSideNav]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptSelectionWithData;