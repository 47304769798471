import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../assets/nav-data-upload.svg';
import FileUploadStatus from './FileUploadStatus';
import { UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import { USER_ROLES } from '../../constants';
import FileTypesValues from '../../interfaces/FileType';
import UploadPrompt from '../data upload/upload prompts/UploadPrompt';
import { useLocation } from 'react-router-dom';
import useUserDetails from '../hooks/useUserDetails';
import CustomPagination from './CustomPagination';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tag from './Tag';
import ArrowRight from '../assets/data-upload-arrow-right.svg';
import ArrowDown from '../assets/data-upload-arrow-down.svg';
import CustomDivider from './CustomDivider';
import UploadCardContext from '../../context/UploadCardContext';
import CustomButton from './CustomButton';
import DownloadIcon from '../assets/download-icon.svg';

import './UploadCard.css';

interface UploadCardProps {
  title: string;
  helperText: string[];
  uploadedFilesData: UploadedFileData[];
  fileType: FileTypesValues;
  cardId: string;
  handleSelectedCardId: Dispatch<SetStateAction<string | null>>;
  sampleFileName: string;
}

function UploadCard({ title, helperText, uploadedFilesData, fileType, cardId, handleSelectedCardId, sampleFileName }: UploadCardProps) {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileIdToReplace = useRef<string | null>(null);
  const containerDiv = useRef<HTMLDivElement>(null);

  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;
  const isFileUploadAllowed = userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.DISTRICT_DATA_LEAD;

  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const hasAnchorId = location.hash && location.hash.length > 0;
    const shouldScrollToCard = containerDiv.current && location.hash.includes(cardId);

    if (!hasAnchorId || !shouldScrollToCard) return;

    containerDiv.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleDrop = (file: File[]) => {
    // drop zone is limited to 1 file from configs
    setFile(file[0]);
  };

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop: handleDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
  });

  const handleClosePrompt = () => {
    setFile(null);
    fileIdToReplace.current = null;
  };

  const handleResubmit = (currentFileId: string) => {
    open();
    fileIdToReplace.current = currentFileId;
  };

  const getFileToReplaceMetadata = () => {
    let fileData = uploadedFilesData.find(fileData => fileData.file_id === fileIdToReplace.current);
    if (!fileData) return {};

    let previousMetadata = fileData.user_provided_file_metadata;
    let parsedMetadata: { [key: string]: string[] } = {};

    for (const metadataKey in previousMetadata) {
      let metaData = previousMetadata[metadataKey];
      if (metaData) parsedMetadata[metadataKey] = metaData;
    }

    return parsedMetadata;
  };

  return (
    <>

      <Accordion id={cardId} ref={containerDiv} defaultExpanded>
        <AccordionSummary
          expandIcon={<img alt="expand accordion icon" src={ArrowDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="upload-card-header">
            <h2>{title}</h2>
            <Tag type="progress" text="4/15 School Data Uploaded" className="upload-progress-info" />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="upload-card-helper-text">
            {helperText.map((text, index) => <p key={index}>{text}</p>)}
          </div>
          <div className="upload-card-actions">
            <CustomButton
              variant="text"
              buttonText="See what to upload"
              buttonAction={() => handleSelectedCardId(cardId)}
              icon={<img src={ArrowRight} alt="open modal / open sidebar"></img>}
              iconPosition="end"
              sx={{ marginLeft: 'calc(var(--scaling-spacing-16) * -1)' }}
            />
            <a data-testid="example file link" href={`/example files/${sampleFileName}`} download={sampleFileName}>
              <CustomButton
                variant="text"
                buttonText="View sample file"
                icon={<img src={DownloadIcon} alt="download"></img>}
                iconPosition="end"
                buttonAction={() => { }}
              />
            </a>
          </div>
          <div data-testid="upload-card-content" className={`upload-card-content ${isSmallScreen ? 'small-screen' : ''}`}>
            {isFileUploadAllowed &&
              <div data-testid="drop-zone" className="upload-card-drop-zone">
                <section>
                  <div {...getRootProps()} className={`drop-zone ${isDragAccept ? 'drag-active' : ''}`}>
                    <input data-testid="drop-zone-file-input" {...getInputProps()} />
                    <p className="drop-zone-text">All .csv, .xls, .xlsx file types are supported</p>
                    <img src={UploadIcon} alt="upload file icon" className="upload-icon"></img>
                    <p className="drop-zone-text">Drag files here to upload</p>
                    <p className="drop-zone-text link-color">or browse for files</p>
                  </div>
                </section>
                {/* <div className="separator">
                  <span className="separator-text">or</span>
                </div>
                <button className="sign-in-button" disabled>Sign in via SIS</button> */}
              </div>
            }
            {uploadedFilesData && uploadedFilesData.length > 0 ?
              <div className="uploaded-files">
                {uploadedFilesData.map((fileStatusData, index) => (
                  <FileUploadStatus
                    key={index}
                    fileId={fileStatusData.file_id}
                    fileName={fileStatusData.file_name}
                    fileSize=""
                    fileStatus={fileStatusData.status}
                    fileMetadata={fileStatusData.user_provided_file_metadata}
                    results={fileStatusData.results_summary}
                    statistics={fileStatusData.statistics}
                    onResubmit={handleResubmit}
                    fileType={fileType}
                    uploadProgress={uploadProgress}
                  />
                ))}
                <CustomPagination
                  currentPage={1}
                  totalItems={50}
                  pages={Math.ceil(50 / 10)}
                  pageSize={10}
                  handlePageChange={() => { }}
                />
              </div>
              :
              null
            }
          </div>
        </AccordionDetails>
      </Accordion>

      {file ?
        <UploadCardContext.Provider value={{
          handleClose: handleClosePrompt,
          fileName: file.name,
          fileType: fileType,
          file: file,
          fileIdToReplace: fileIdToReplace.current,
          handleUploadProgress: setUploadProgress,
          fileMetadata: getFileToReplaceMetadata(),
          triggerFileSelect: open
        }} >
          <UploadPrompt />
        </UploadCardContext.Provider>
        :
        null
      }
      <CustomDivider orientation="horizontal" />
    </>
  );
}

export default UploadCard;