import CustomDivider from '../../reusable/CustomDivider';
import LollipopChart from '../../reusable/LollipopChart';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface DemographicsContentSectionProps {
  header: { title: string, description: string }
  studentsCount: number;
  countDescription: string;
  genderData: { [key: string]: number };
  dotColors: string[];
  raceChartData: { labels: (string | string[])[]; data: number[]; color: string };
  otherData: {
    lowIncome: number;
    disabilities: number;
    englishLearner: { learner: number; native: number, other?: number };
  };
  classes?: string[]
}

function DemographicsContentSection({ header, studentsCount, countDescription, genderData, dotColors, raceChartData, otherData, classes }: DemographicsContentSectionProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const classNames = classes ? classes.join(' ') : '';

  const sortedGenderData = Object.entries(genderData).sort((dataPoint1, dataPoint2) => (dataPoint2[1] - dataPoint1[1]));

  return (
    <div className="demographics-content-section">
      <div className="demographics-content-section-header">
        <span>{header.title}</span>
        <span>{header.description}</span>
      </div>
      <div className="students-total-count" data-testid="students-count-info"><span>{studentsCount}</span><p>{countDescription}</p></div>
      <CustomDivider orientation="horizontal" className="light" />
      <div className="demographics-content-statistics">
        <span>Gender</span>
        <div className="bars">
          {sortedGenderData.map(([key, value], index) => (
            <div 
              data-testid={`section-${key}`} 
              key={key} 
              style={{ width: `${value}%`, backgroundColor: dotColors[index] }} 
              className={classNames}
            >
            </div>
          ))}
        </div>
        <div className={`bar-percentages ${isSmallScreen ? 'small-screen' : ''}`}>
          {sortedGenderData.map(([key, value], index) => (
            <div key={key} className="legend" data-testid={`legend-${key}`}>
              <div style={{ backgroundColor: dotColors[index] }} className="color-dot"></div>
              <span>{value}%</span>
              <p className="gender capitalized"> {key.toLowerCase()}</p>
            </div>
          ))}
        </div>
      </div>
      <CustomDivider orientation="horizontal" className="light" />
      <div className="chart-section">
        <span className="chart-title">Race/ethnicity</span>
        <div className="lollipop-chart-container">
          {raceChartData.data && raceChartData.labels ? 
            <LollipopChart
              dataValues={raceChartData.data}
              labels={raceChartData.labels}
              secondDataset
              firstDatasetColor={raceChartData.color}
              secondDatasetColor="#E5E5E5"
              maintainAspectRatio={false}
            />
            :
            <p>Error occured while fetching demographics data!</p>
          }
        </div>
      </div>

      {/* OTHER DATA */}
      <div className="demographics-content-statistics other-data">
        {/* SOCIO-DIS SECTION */}
        <div className={`socioeconomic-disability-status ${isSmallScreen ? 'small-screen' : ''}`}>
          {/* SOCIOECONOMIC */}
          <div className="socioeconomic-status" data-testid="socioeconomic-status">
            <div className="header">
              <span>Socioeconomic status</span>
              <div className="line"></div>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.lowIncome}%</p>
              <span>Low income</span>
            </div>
          </div>
          {/* DISABILITIES */}
          <div className="disability-status" data-testid="disability-status">
            <div className="header">
              <span>Disability status</span>
              <div className="line"></div>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.disabilities}%</p>
              <span>Students with disabilities</span>
            </div>
          </div>
        </div>
        {/* ELL */}
        <div className="ell-status" data-testid="ell-status">
          <div className="header">
            <span>ELL status</span>
            <div className="line"></div>
          </div>
          <div className={`el-native-status ${isSmallScreen ? 'small-screen' : ''}`}>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.englishLearner.learner}%</p>
              <span>English learners</span>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.englishLearner.native}%</p>
              <span>Native/Fluent</span>
            </div>
            { otherData.englishLearner.other ?
              <div className={`cell ${classNames}`}>
                <p className="cell-value">{otherData.englishLearner.other}%</p>
                <span>Other</span>
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemographicsContentSection;