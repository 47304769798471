import axios from 'axios';
import { 
  MatchingDetails, 
  CtePathwaysDetails, 
  CourseCatalogDetails, 
  TestScoresDetails 
} from '../interfaces/DataQcInterface';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getQueryParams = (school_id?: string) => {
  if (!school_id || school_id.length === 0) return {};

  return {
    school_id: school_id
  };
};

const getMatchingDetails = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  return await axios.get<MatchingDetails>(`${BASE_URL}/eoa/${eoaId}/data-qc/matching/detail`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getTestScoresDetails = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);

  const paths = [
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/sat-histogram`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/act-histogram`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/ap-histogram`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/ib-histogram`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/ap-subject-area-counts`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/test-scores/detail/ib-subject-area-counts`
  ];
  
  const requests = paths.map(path => 
    axios.get<TestScoresDetails>(path, {
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      params
    })
  );

  return await Promise.allSettled(requests);
};

const getCtePathwaysDetails = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);
  
  return await axios.get<CtePathwaysDetails>(`${BASE_URL}/eoa/${eoaId}/data-qc/cte-pathways/detail`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getCourseCatalogsDetails = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);
    
  return await axios.get<CourseCatalogDetails>(`${BASE_URL}/eoa/${eoaId}/data-qc/course-catalogs/detail`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const getTranscriptsDetails = async (eoaId: string, access_token: string, school_id: string) => {
  const params = getQueryParams(school_id);
  
  const paths = [
    `${BASE_URL}/eoa/${eoaId}/data-qc/transcripts/detail/gpa-histogram`,
    `${BASE_URL}/eoa/${eoaId}/data-qc/transcripts/detail/subject-area-average-credits`,
  ];
    
  const requests = paths.map(path => 
    axios.get<{scores: number[]} | { [key: string]: number }>(path, {
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      params
    })
  );
  
  return await Promise.allSettled(requests);
};

export {
  getMatchingDetails,
  getTestScoresDetails,
  getCtePathwaysDetails,
  getCourseCatalogsDetails,
  getTranscriptsDetails
};