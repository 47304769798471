import SuccessIcon from './assets/data-upload-success.svg';
import WarningIcon from './assets/data-upload-warning-amber.svg';
import { UploadRequirementsList } from '../interfaces/UploadedFileDataInterface';
import { AxiosResponse } from 'axios';
import { withData } from './reusable/withData';

import './SchoolsList.css';
import CustomDivider from './reusable/CustomDivider';

type FetchFunction = (eoaId: string, access_token: string) => Promise<AxiosResponse<UploadRequirementsList>>;

interface SchoolsListProps {
  data: UploadRequirementsList;
}


export function SchoolsList({ data }: SchoolsListProps) {

  const prepareData = () => {

    const sections = [
      {
        requirementMet: false,
        title: 'Data not yet uploaded',
        schools: [] as [string, boolean | string][],
        icon: null,
        iconAltText: ''
      },
      {
        requirementMet: 'error',
        title: 'Data uploaded with errors',
        schools: [] as [string, boolean | string][],
        icon: WarningIcon,
        iconAltText: 'warning icon'
      },
      {
        requirementMet: true,
        title: 'Completed uploads',
        schools: [] as [string, boolean | string][],
        icon: SuccessIcon,
        iconAltText: 'check mark icon'
      }
    ];
   
    Object.entries(data.schools).forEach(([schoolName, requirementMet]) => {
      const section = sections.find(s => s.requirementMet === requirementMet);
      if (section) section.schools.push([schoolName, requirementMet]);
    });

    return sections;
  };

  const sections = prepareData();

  return (
    <>
      {sections.map((section, index) => (
        <div key={index}>
          <CustomDivider orientation="horizontal" size="medium" />
          <SchoolsWithStatus
            title={section.title}
            schools={section.schools}
            icon={section.icon}
            iconAltText={section.iconAltText}
          />
        </div>
      ))}
    </>
  );
}

interface SchoolsWithStatusProps {
  title: string; 
  schools: [string, boolean | string][]; 
  icon?: string | null;
  iconAltText: string;
}

function SchoolsWithStatus({ title, schools, icon, iconAltText }: SchoolsWithStatusProps) {

  return (
    <>
      <div className="data-status">
        <p>{title}</p>
        <span>{`${schools.length} schools`}</span>
      </div>
      {schools.map(([schoolName], index) => (
        <div key={index} className="school-container">
          { icon ?
            <img className="icon-size" src={icon} alt={iconAltText}/>
            :
            null
          }
          <span>{schoolName}</span>
        </div>
      ))}
    </>
  );
}

const SchoolListWithData = ({ fetchData }: { fetchData: FetchFunction }) => {

  const WrappedComponent = withData({
    WrappedComponent: SchoolsList,
    fetchData: fetchData,
    errorMessage: 'Error occurred while fetching upload requirements data!'
  });

  return <WrappedComponent />;
};

export default SchoolListWithData;
