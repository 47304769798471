import { forwardRef } from 'react';
import CustomButton from '../reusable/CustomButton';
import { Link } from 'react-router-dom';
import { ButtonOwnProps } from '@mui/material';

import './OverviewStepCard.css';

interface OverviewStepCardProps {
  cardIcon: string;
  stepNumber: number;
  cardTitle: string;
  cardDescription: string;
  buttonText: string;
  isActive: boolean;
  redirectTo: string;
}

export const LinkComponent = forwardRef<HTMLAnchorElement, ButtonOwnProps>((props, ref) => {
  if (!props.href) throw new Error('Missing href property');

  return <Link {...props} to={props.href} ref={ref}></Link>;
});

function OverviewStepCard({ cardIcon, stepNumber, cardTitle, cardDescription, buttonText, isActive, redirectTo }: OverviewStepCardProps) {
  return (
    <div data-testid="overview-step-card" className={`overview-step-card ${isActive ? 'active' : ''}`}>
      <img src={cardIcon} alt="icon" />
      <div className="card-header">
        <p>Step {stepNumber}</p>
        <p className="card-title">{cardTitle}</p>
      </div>
      <p className="card-description">{cardDescription}</p>
      <div className="card-button">
        { isActive ?
          <CustomButton 
            variant="primary" 
            buttonText={buttonText} 
            buttonAction={() => {}} 
            size="medium" 
            linkComponent={LinkComponent}
            href={redirectTo}
          />
          :
          <CustomButton 
            variant="secondary" 
            buttonText={buttonText} 
            buttonAction={() => {}} 
            size="medium" 
            linkComponent={LinkComponent}
            href={redirectTo}
          />
        }
      </div>
    </div>
  );
}

export default OverviewStepCard;