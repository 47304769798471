import CustomButton from '../reusable/CustomButton';
// import CustomTable from '../reusable/CustomTable';
import TranscriptsBySubjectArea from './TranscriptsBySubjectArea';
import { Transcript } from '../../interfaces/TranscriptsInterface';
import { useSelectedTranscripts } from '../hooks/useSelectedTranscripts';
import Loader from '../reusable/Loader';
// import TranscriptCollapsibleTableRow from './TranscriptCollapsibleTableRow';

import './TranscriptSelectionTables.css';

interface TranscriptSelectionTablesProps {
  isFinalized: boolean;
  categories: {
    name: string;
    label: string;
    filteredData: Transcript[];
    selectionLimit: number;
  }[] | null;
  isRestrictedView: boolean;
}

// const tableHeaderData = [
//   { columnName: 'Student' }, 
//   { columnName: 'GPA' }, 
//   { columnName: 'Credits' }, 
//   { columnName: 'Race/Eth' },
//   { columnName: 'Gender' }, 
//   { columnName: 'Disability status' }, 
//   { columnName: 'English learner' },
//   { columnName: 'Low income' }, 
//   { columnName: 'Actions' }, 
// ];

function TranscriptSelectionTables({ isFinalized, categories, isRestrictedView }: TranscriptSelectionTablesProps) {
  const { selectedTranscripts } = useSelectedTranscripts();

  if(!categories) return <Loader />;
  
  return (
    <div className="transcript-selection-tables-container">
      {categories.map(({ name, label, filteredData, selectionLimit }) => {
        const selectedCount = selectedTranscripts.filter(transcript => transcript.category === name).length;
        return (
          <div className="transcript-selection-category-container" key={name}>
            <div className="transcript-selection-category-header">
              <p className="transcript-selection-category-title">
                {`${label} (${selectedCount} / ${selectionLimit} selected)`}
              </p>
              <div className="transcript-selection-category-header-actions">
                <CustomButton variant="secondary" buttonText="Print All" buttonAction={() => console.log('print all')} />
                { !isFinalized && !isRestrictedView ?
                  <CustomButton variant="secondary" buttonText="Save selection progress" buttonAction={() => console.log('save selection progress')} />
                  :
                  null
                }
              </div>
            </div>
            {/* <CustomTable tableHeaderData={tableHeaderData}> 
              {filteredData.map((row, index) => (
                <TranscriptCollapsibleTableRow
                  key={`${row.id}-${index}`} 
                  row={row}
                  selectionLimit={selectionLimit}
                  selectedCount={selectedCount}
                  isRestrictedView={isRestrictedView}
                />
              ))}
            </CustomTable> */}
          </div>
        );
      })}
      { isFinalized ?
        <div className="transcript-selection-category-container transcripts-view">
          <TranscriptsBySubjectArea />
        </div>
        :
        null
      }
    </div>
  );
}

export default TranscriptSelectionTables;