import { createContext } from 'react';
import { AxiosError } from 'axios';

interface ErrorModalContextValue {
  errorMessage: string;
  isOpen: boolean;
  handleOpen: (errorMessage: string, error: Error | AxiosError) => void;
  handleClose: () => void;
}

const ErrorModalContext = createContext<ErrorModalContextValue>({
  errorMessage: '',
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export default ErrorModalContext;