import VerticalBarChart from '../../reusable/VerticalBarChart';
import { getTestScoresDetails } from '../../../services/dataQcDetailsService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import './TestScoresDrawerContainer.css';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import { withData } from '../../reusable/withData';
import { TestScoresDetails } from '../../../interfaces/DataQcInterface';
import { AxiosResponse } from 'axios';
import CustomDivider from '../../reusable/CustomDivider';

interface TestScoresDetailsProps {
  data: PromiseSettledResult<AxiosResponse<TestScoresDetails>>[];
}

interface ChartData {
  scores: number[];
  labels: string[];
  student_count_not_taken_test: number;
  student_count_taken_test: number;
}

const parseData = (data: Awaited<ReturnType<typeof getTestScoresDetails>>) => {

  const props = ['sat', 'act', 'ap', 'ib', 'apSubjects', 'ibSubjects'];

  const parsedDataObject = {
    sat: { scores: [], labels: [], student_count_not_taken_test: 0, student_count_taken_test: 0 } as ChartData | null,
    act: { scores: [], labels: [], student_count_not_taken_test: 0, student_count_taken_test: 0 } as ChartData | null,
    ap: { scores: [], labels: [], student_count_not_taken_test: 0, student_count_taken_test: 0 } as ChartData | null,
    ib: { scores: [], labels: [], student_count_not_taken_test: 0, student_count_taken_test: 0 } as ChartData | null,
    apSubjects: {} as { [key: string]: number } | null,
    ibSubjects: {} as { [key: string]: number } | null
  };

  data.forEach((dataResult, index) => {
    let property = props[index];

    switch (property) {
    case 'sat':
    case 'act':
    case 'ap':
    case 'ib':
      if (dataResult.status === 'fulfilled' && dataResult.value.data) {
        let scores: number[] = [];
        let labels = new Set<string>();

        dataResult.value.data.ranges.forEach(range => {
          scores.push(range.count);
          labels.add(range.start.toString());
          labels.add(range.end.toString());
        });

        parsedDataObject[property] = {
          scores: scores,
          labels: Array.from(labels),
          student_count_not_taken_test: dataResult.value.data.student_count_not_taken_test ?? 0,
          student_count_taken_test: dataResult.value.data.student_count_taken_test ?? 0,
        };
      } else parsedDataObject[property] = null;
      break;
    case 'apSubjects':
    case 'ibSubjects': 
      if (dataResult.status === 'fulfilled' && dataResult.value.data.subjects) parsedDataObject[property] = { ...dataResult.value.data.subjects };
      else parsedDataObject[property] = null;
      break;
    }
  });

  return parsedDataObject;
};

export function TestScoresDrawerContent({data}: TestScoresDetailsProps) {
  const { sat, act, ap, ib, apSubjects, ibSubjects } = parseData(data);

  return (
    <>
      <div className="drawer-section chart-section">
        <p className="section-title">SAT superscore distribution</p>
        { sat ?
          <>
            <p className="chart-title">Students in EOA cohort: {sat.student_count_taken_test}</p>
            <p className="chart-sub-title">{sat.student_count_not_taken_test} did not take SAT</p>
            <VerticalBarChart 
              labels={sat.labels} 
              dataSets={[{data: sat.scores, backgroundColor: darkGreenBarColor}]} 
              displayDataLabels={false} 
            />
          </>
          :
          <p>Error occured while fetching "SAT superscore distribution" data!</p>
        }
      </div>

      <CustomDivider orientation="horizontal" />

      <div className="drawer-section chart-section">
        <p className="section-title">ACT superscore distribution</p>
        { act ?
          <>
            <p className="chart-title">Students in EOA cohort: {act.student_count_taken_test}</p>
            <p className="chart-sub-title">{act.student_count_not_taken_test} did not take ACT</p>
            <VerticalBarChart 
              labels={act.labels} 
              dataSets={[{data: act.scores, backgroundColor: darkGreenBarColor}]} 
              displayDataLabels={false} 
            />
          </>
          :
          <p>Error occured while fetching "ACT superscore distribution" data!</p>
        }
      </div>

      <CustomDivider orientation="horizontal" />

      <div className="drawer-section chart-section">
        <p className="section-title">AP score distribution</p>
        { ap ?
          <>
            <p className="chart-title">Students in EOA cohort: {ap.student_count_taken_test}</p>
            <VerticalBarChart 
              labels={ap.labels} 
              dataSets={[{data: ap.scores, backgroundColor: darkGreenBarColor}]} 
              displayDataLabels={false} 
              labelOffset={0}
            />
          </>
          :
          <p>Error occured while fetching "AP score distribution" data!</p>
        }
      </div>
      <div className="drawer-section">
        <p className="section-title">Number of tests found by subject area</p>
        <div className="average-data-section">
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          {apSubjects ? 
            Object.entries(apSubjects).map(([key, value]) => (
              <div key={key} className="cell" data-testid="ap-subject-info">
                <p className="cell-value no-margin">{value}</p>
                <span>{key}</span>
              </div>
            ))
            :
            <p>Error occured while fetching "Number of tests found by subject area" data!</p>
          }
        </div>
      </div>

      <CustomDivider orientation="horizontal" />

      <div className="drawer-section chart-section">
        <p className="section-title">IB score distribution</p>
        { ib ?
          <>
            <p className="chart-title">Students in EOA cohort: {ib.student_count_taken_test}</p>
            <VerticalBarChart 
              labels={ib.labels} 
              dataSets={[{data: ib.scores, backgroundColor: darkGreenBarColor}]} 
              displayDataLabels={false} 
              labelOffset={0}
            />
          </>
          :
          <p>Error occured while fetching "IB score distribution" data!</p>
        }
      </div>
      <div className="drawer-section">
        <p className="section-title">Average number of tests taken per subject area</p>
        <div className="average-data-section">
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          {ibSubjects ?
            Object.entries(ibSubjects).map(([key, value]) => (
              <div key={key} className="cell" data-testid="ib-subject-info">
                <p className="cell-value">{value}</p>
                <span>{key}</span>
              </div>
            ))
            :
            <p>Error occured while fetching "Average number of tests taken per subject area" data!</p>
          }
        </div>
      </div>
    </>
  );
}

const TestScoresDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TestScoresDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getTestScoresDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching test scores details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TestScoresDrawerContentWithData;
