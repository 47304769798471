const fileTypes = Object.freeze({
  STUDENT_ROSTER: 'STUDENT_ROSTER',
  COURSE_CATALOG: 'COURSE_CATALOG',
  STUDENT_DEMOGRAPHICS: 'STUDENT_DEMOGRAPHICS',
  STUDENT_TRANSCRIPTS: 'TRANSCRIPT',
  TEST_SCORES: 'STUDENT_TEST_SCORES',
  CTE_PATHWAYS: 'CTE_PATHWAY_CATALOG'
});

const fileTypesLabels = Object.freeze({
  [fileTypes.STUDENT_ROSTER]: 'Student Roster',
  [fileTypes.COURSE_CATALOG]: 'Course Catalog',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'Student Demographics',
  [fileTypes.STUDENT_TRANSCRIPTS]: 'Student Transcripts',
  [fileTypes.TEST_SCORES]: 'Test Scores',
  [fileTypes.CTE_PATHWAYS]: 'CTE Pathways'
});

const SCHOOL_LOCAL_STORAGE_KEY = 'school';
const DISTRICT_LOCAL_STORAGE_KEY = 'district';

const AUDIT_LOG_ITEMS_PER_PAGE = 10;
const DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE = 10;
const STUDENT_ROSTER_ITEMS_PER_PAGE = 10;

const HIGH_FLIERS_SELECTION_LIIMIT = 2;
const ON_THE_CUSP_SELECTION_LIIMIT = 4;
const STRUGGLING_SELECTION_LIIMIT = 2;

const USER_ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  FACILITATOR: 'FACILITATOR',
  FACILITATOR_MANAGER: 'SCHOOL_FACILITATOR+ACCOUNT_MANAGER',
  ACCOUNT_MANAGER: 'XQ_ACCOUNT_MANAGER', // aka XQ Admin
  DISTRICT_DATA_LEAD: 'DISTRICT_DATA_LEAD',
  XQ_USER: 'XQ_USER'
});

const UPLOAD_SECTIONS_IDS = Object.freeze({
  [fileTypes.STUDENT_ROSTER]: 'studentRoster',
  [fileTypes.COURSE_CATALOG]: 'courseCatalog',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'studentDemographics',
  [fileTypes.STUDENT_TRANSCRIPTS]: 'studentTranscripts',
  [fileTypes.TEST_SCORES]: 'testScores',
  [fileTypes.CTE_PATHWAYS]: 'ctePathways'
});

const NAVIGATION_LINKS_DATA = Object.freeze({
  OVERVIEW: {
    path: '/overview',
    label: 'Overview'
  },
  DATA_UPLOAD: {
    path: '/data-upload',
    label: 'Data Upload'
  },
  DATA_QC: {
    path: '/data-qc',
    label: 'Quality Check'
  },
  TRANSCRIPTS: {
    path: '/transcripts',
    label: 'Transcript Selection'
  }
});

const DEMOGRAPHIC_PERCENTAGES = Object.freeze({
  RACE: {
    PACIFIC_ISLANDER: 'pacific islander',
    WHITE: 'white',
    ASIAN: 'asian',
    UNKNOWN: 'unknown',
    AMERICAN_INDIAN: 'american indian',
    LATINX: 'latinx',
    OTHER: 'other',
    TWO_OR_MORE: 'two or more',
    BLACK: 'black',
  },
  INCOME: {
    LOW_INCOME: 'low-income',
    ABOVE_LOW_INCOME: 'above low-income',
  },
  ENGLISH_LEARNER: {
    NATIVE: 'native / fluent',
    LEARNER: 'english language learner',
    OTHER: 'other'
  },
  DISABILITIES: {
    WITHOUT_DISABILITIES: 'without disabilities',
    WITH_DISABILITIES: 'with disabilities',
  }
});

export {
  fileTypes,
  SCHOOL_LOCAL_STORAGE_KEY,
  DISTRICT_LOCAL_STORAGE_KEY,
  AUDIT_LOG_ITEMS_PER_PAGE,
  DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE,
  STUDENT_ROSTER_ITEMS_PER_PAGE,
  HIGH_FLIERS_SELECTION_LIIMIT,
  ON_THE_CUSP_SELECTION_LIIMIT,
  STRUGGLING_SELECTION_LIIMIT,
  USER_ROLES,
  UPLOAD_SECTIONS_IDS,
  NAVIGATION_LINKS_DATA,
  DEMOGRAPHIC_PERCENTAGES,
  fileTypesLabels,
};