import './CustomDivider.css';

interface DividerProps {
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  orientation: 'horizontal' | 'vertical';
  className?: string;
}

function CustomDivider({ size, orientation, className }: DividerProps) {
  return (
    <div className={`divider ${orientation} ${size ? size : ''} ${ className ? className : '' }`}></div>
  );
}

export default CustomDivider;