interface TranscriptSelectionSummary {
  is_finalized: boolean,
  transcripts: Transcript[];
}
interface Transcript {
  id: string,
  category: string,
  gpa: number,
  credits: number,
  race: string,
  gender: string,
  disability_status: string,
  english_learner: string,
  low_income: string
}

interface Course {
  name: string;
  code: string;
  letter_grade: string | null;
  number_grade: number | null;
  num_credits: number;
}

interface SubjectArea {
  name: string;
  num_credits: number;
  courses: Course[];
}

interface Term {
  name: string;
  subject_areas: SubjectArea[]
}

interface AcademicPeriod {
  period: string;
  terms: Term[]
}

interface TranscriptDetails {
  id: string;
  category: string;
  gpa: number;
  credits: number;
  race: string;
  gender: string;
  disability_status: string;
  english_learner: string;
  low_income: string;
  academic_periods: AcademicPeriod[];
}

interface Category {
  name: string;
  label: string;
}

export enum ChartCategoryTypes {
  RACE = 'race',
  GENDER = 'gender',
  DISABILITY_STATUS = 'disability_status',
  ENGLISH_LEARNER = 'english_learner',
  LOW_INCOME = 'low_income'
}

export type {
  TranscriptSelectionSummary,
  Transcript,
  TranscriptDetails,
  Category
};
