import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import useSchools from '../hooks/useSchools';
import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';
import { NAVIGATION_LINKS_DATA } from '../../constants';
import SelectDropdown from '../reusable/SelectDropdown';

import './DataQcLayout.css';

const pageDescription = 'Review uploaded district data and make sure it\'s accurate.';
const allSchoolsValue = 'All schools';

function DataQcLayout() {
  const [contextData, setContextData] = useState({ selectedSchoolFromDataQc: '' });

  const { schools: { isPending, error, data: schools } } = useSchools();

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    if (value === allSchoolsValue) setContextData({ selectedSchoolFromDataQc: '' });
    else setContextData({ selectedSchoolFromDataQc: value });
  };

  const getDropdownItems = () => {
    if (!schools) return [];

    let items = [{ label: allSchoolsValue, value: allSchoolsValue}];
    schools.forEach((school) => items.push({ label: school.name, value: school.id }));

    return items;
  };

  return (
    <>
      <PageHeader title={NAVIGATION_LINKS_DATA.DATA_QC.label} pageDescription={pageDescription} />
      <div className="select-school-bar">
        {isPending ? <Loader /> : null}
        {error ? <p>Missing schools data!</p> : null}
        { schools && 
          <>
            <span>Show data for</span>
            <SelectDropdown
              label="School"
              selectedValue={contextData.selectedSchoolFromDataQc || allSchoolsValue}
              handleChange={handleChange}
              items={getDropdownItems()}
              isClearable={false}
            />
          </>
        }
      </div>

      <Outlet context={contextData} />
    </>
  );
}

export default DataQcLayout;