import { useOutletContext } from 'react-router-dom';

interface OutletContextValues {
  selectedSchoolFromDataQc: string;
}

export function useDataQcOutletContext() {
  return useOutletContext<OutletContextValues>();
}

export type {
  OutletContextValues
};