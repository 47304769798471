// import FileIconGreen from '../assets/file-icon-green.svg';
import CloseIcon from '../assets/close-icon.svg';
import './WhatToUpload.css';  
import useUserDetails from '../hooks/useUserDetails';

interface WhatToUploadProps {
  cardTitle: string;
  fileTypeLabel: string;
  handleCloseSidebar: () => void;
  children: React.ReactNode;
}

const WhatToUpload = ({ cardTitle, fileTypeLabel, handleCloseSidebar, children }: WhatToUploadProps) => {

  const { data: userDetails } = useUserDetails();

  return (
    <>
      <div className="helper-section">
        <div className="helper-section-title"> 
          <h2>{cardTitle}</h2>
          <img className="pointer" src={CloseIcon} alt="close sidebar icon" onClick={handleCloseSidebar}/>
        </div>
        <p>
          Please upload the {fileTypeLabel} for the graduating class of {userDetails?.graduatingClass} for all schools participating in the Educational Opportunity Audit.
          If your file contains data for multiple schools, make sure it includes at least the Student ID and School ID for each student.
        </p>
        {/* <div className="example-file-container">
          <img alt="file icon" src={FileIconGreen}/>
          <div className="file-info-container">
            <a data-testid="example file link" href={`/example files/${sampleFileName}`} download={sampleFileName}>Download an example file</a>
          </div>
          <div className="spacer"></div>
        </div> */}
      </div>
      {children}
    </>
  );
};

export default WhatToUpload;