import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);

interface LollipopChartProps {
  labels: (string | string[])[];
  dataValues: number[];
  secondDataset?: boolean;
  firstDatasetColor: string;
  secondDatasetColor?: string;
  maintainAspectRatio?: boolean
}

interface Dataset {
  axis: string;
  label: string;
  data: number[];
  backgroundColor: string;
  borderSkipped: boolean;
  barPercentage: number;
  grouped?: boolean;
  order?: number;
}

function LollipopChart({ labels, dataValues, secondDataset, firstDatasetColor, secondDatasetColor, maintainAspectRatio = true }: LollipopChartProps) {

  const lollipopChart = {
    id: 'lollipopChart',
    afterDatasetsDraw(chart: any, args: any, options: any) {
      const { ctx } = chart;
      ctx.save();

      chart.data.datasets.forEach((dataset: { data: any[]; label: any; }, datasetIndex: number) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (datasetIndex === 0) {

          for (let i = 0; i < meta.data.length; i++) {
            const dataPoint = meta.data[i];
            const x = dataPoint.x;
            const y = dataPoint.y;
            const value = dataset.data[i];

            drawCircle(x, y);

            ctx.fillStyle = '#FFFFFF';
            ctx.font = 'bold 14px Roobert';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const displayText = `${value}%`;
            ctx.fillText(displayText, x, y);
          }
        }
      });

      ctx.restore();

      function drawCircle(xPos: any, yPos: any) {
        const angle = Math.PI / 180;
        ctx.beginPath();
        ctx.fillStyle = firstDatasetColor;
        ctx.arc(xPos, yPos, 22, angle * 0, angle * 360, false);
        ctx.fill();
        ctx.closePath();
      }
    }
  };

  const data: { labels: (string | string[])[]; datasets: Dataset[] } = {
    labels,
    datasets: [{
      axis: 'y',
      label: 'actual',
      data: dataValues,
      backgroundColor: firstDatasetColor,
      borderSkipped: false,
      barPercentage: 0.1,
    }]
  };

  if (secondDataset) {

    const secondDataset: Dataset = {
      axis: 'y',
      label: 'total',
      data: new Array(dataValues.length).fill(100),
      backgroundColor: secondDatasetColor ? secondDatasetColor : '',
      borderSkipped: false,
      barPercentage: 0.1,
      grouped: false,
      order: 1
    };

    data.datasets = [...data.datasets, secondDataset];
  }

  return (
    <Chart
      plugins={[ChartDataLabels, lollipopChart]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: maintainAspectRatio,
        aspectRatio: 2 / 0.9,
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
          datalabels: {
            display: false,
          },
        },
        indexAxis: 'y',
        layout: {
          padding: {
            right: 20
          }
        },
        scales: {
          x: {
            min: -2,
            max: 100,
            grid: {
              display: false
            },
            ticks: {
              display: false
            },
            border: {
              display: false
            },
            beginAtZero: false,
          },
          y: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            ticks: {
              color: '#000000',
              font: {
                size: 16
              },
              padding: 0,
              autoSkip: false,
            }
          }
        }
      }}
      data={data}
    />
  );
}

export default LollipopChart;