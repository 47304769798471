import { Outlet, useLocation } from 'react-router-dom';
import SideBarNavigation from './SideBarNavigation';
import Footer from '../footer/Footer';
import XQLogo from '../assets/xq-logo.svg';
import UserProfile from './UserProfile';
import { useState } from 'react';
import CustomButton from '../reusable/CustomButton';
import ArrowFwdIcon from '../assets/nav-arrow-fwd.svg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import './NavigationLayout.css';
import SelectedDistrictBar from './SelectDistrictBar';

const NavigationLayout = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isNavOpen, setIsNavOpen] = useState(isSmallScreen ? false : true);

  const location = useLocation();

  return (
    <div className="App">
      <div className="top-bars-container">
        <div className="top-navigation">
          <div className="logo-container">
            <img src={XQLogo} alt="xq eduational opportunity audit"></img>
          </div>
          <UserProfile />
        </div>
        { location.pathname !== '/overview' ? 
          <SelectedDistrictBar />
          :
          null
        }
      </div>
      <div data-testid="navigation-layouyt-main-container" className="layout-container">
        {isSmallScreen &&
        <div className="sidebar-overlay-open-button-container">
          <CustomButton 
            variant="clear"
            buttonAction={() => setIsNavOpen(true)}
            sx={{ padding: '0', minWidth: '0' }}
          >
            <img alt="open/close navigation menu" src={ArrowFwdIcon} />
          </CustomButton>
        </div>
        }
        <SideBarNavigation isOpen={isNavOpen} handleOpen={setIsNavOpen}/>
        <main className="main-content-container">
          <Outlet />
          <Footer />
        </main>
        <aside id="sideDrawer"></aside>
      </div>
    </div>
  );
};

export default NavigationLayout;