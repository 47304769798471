import { useState, useEffect } from 'react';
import GlobalStateContext from './GlobalStateContext';
import GlobalState from '../interfaces/GlobalStateInterface';
import { UserDetails, UserDetailsResponse } from '../interfaces/UserDetailsInterface';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserDetails } from '../services/userService';
import { getSchools } from '../services/uploadService';
import { Outlet } from 'react-router-dom';
import { SCHOOL_LOCAL_STORAGE_KEY } from '../constants';
import useErrorModal from '../components/hooks/useErrorModal';
import { AxiosError } from 'axios';

const GlobalStateContextProvider = () => {
  const [globalState, setGlobalState] = useState<GlobalState['globalState']>({ 
    userDetails: { 
      isPending: true, 
      error: null, 
      data: null
    }, 
    schools: {
      isPending: true,
      error: null,
      data: null
    },
    selectedSchoolFromSideNav: localStorage.getItem(SCHOOL_LOCAL_STORAGE_KEY) || ''
  });

  const { getAccessTokenSilently } = useAuth0();

  const { handleOpen: handleOpenErrorModal } = useErrorModal();

  useEffect(() => {
    fetchUserDetailsData();
  }, []);

  useEffect(() => {
    if (globalState.userDetails.data) {
      fetchSchoolsData(globalState.userDetails.data);
    }
  }, [globalState.userDetails.data]);

  const extractEoaData = (data: UserDetailsResponse) => {
    let eoaIndex = 0;
    if (data.eoas.length > 1) eoaIndex = 1;

    return {
      userEoaId: data.eoas[eoaIndex].id,
      userRole: data.eoas[eoaIndex].roles[0].name,
      userRoleDescription: data.eoas[eoaIndex].roles[0].description,
      userDistrict: data.eoas[eoaIndex].district,
      graduatingClass: data.eoas[eoaIndex].year_ending
    };
  };

  const fetchUserDetailsData = async () => {
    try {
      let access_token = await getAccessTokenSilently();
      let userDetailsResponse = await getUserDetails(access_token);
      const { userEoaId, userRole, userDistrict, userRoleDescription, graduatingClass } = extractEoaData(userDetailsResponse.data);

      let userDetails = {
        name: userDetailsResponse.data.name,
        eoaId: userEoaId,
        role: userRole,
        roleDescription: userRoleDescription,
        access_token,
        district: userDistrict,
        graduatingClass
      };

      setGlobalState({ ...globalState, userDetails: { isPending: false, error: null, data: userDetails }});
    } catch (error) {
      setGlobalState({ ...globalState, userDetails: { isPending: false, error: error, data: null } });
      handleOpenErrorModal('Error occured while fetching user details data!', error as AxiosError);
    }
  };

  const fetchSchoolsData = async (userDetails: UserDetails) => {
    try {
      const schoolsResponse = await getSchools(userDetails.eoaId, userDetails.access_token);
      const schools = schoolsResponse.data;
      let newSelectedSchoolValue = globalState.selectedSchoolFromSideNav || schools[0].id;

      if (!localStorage.getItem(SCHOOL_LOCAL_STORAGE_KEY)) localStorage.setItem(SCHOOL_LOCAL_STORAGE_KEY, newSelectedSchoolValue);

      setGlobalState({ ...globalState, schools: { isPending: false, error: null, data: schools }, selectedSchoolFromSideNav: newSelectedSchoolValue });
    } catch (error) {
      setGlobalState({ ...globalState, schools: { isPending: false, error: error, data: null } });
      handleOpenErrorModal('Error occured while fetching schools data!', error as AxiosError);
    }
  };

  const updateSelectedSchool = (newSelectedSchool: string) => {
    localStorage.setItem(SCHOOL_LOCAL_STORAGE_KEY, newSelectedSchool);
    setGlobalState({ ...globalState, selectedSchoolFromSideNav: newSelectedSchool });
  };

  return (
    <GlobalStateContext.Provider value={{globalState, updateSelectedSchool}}>
      <Outlet />
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContextProvider;