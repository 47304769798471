import VerticalBarChart from '../../reusable/VerticalBarChart';
import { getTranscriptsDetails } from '../../../services/dataQcDetailsService';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import { AxiosResponse } from 'axios';
import { TranscriptsDetails } from '../../../interfaces/DataQcInterface';
import CustomDivider from '../../reusable/CustomDivider';

type GPAChartData = {
  labels: string[];
  scores: number[];
};

type GPADistribution = {
  chartData: GPAChartData,
  studentsCount: number;
}

interface TranscriptsSideDrawerContentProps {
  data: PromiseSettledResult<AxiosResponse<TranscriptsDetails>>[];
}

export function TranscriptsSideDrawerContent({data}: TranscriptsSideDrawerContentProps) {
  
  const parseData = () => {
    let gpaDistribution: GPADistribution | null = null;
    let subjectCredits = null;

    if (data[0].status === 'fulfilled') {
      let _data = data[0].value.data;

      const scores: number[] = [];
      const labels = new Set<string>();
      
      _data.ranges.forEach((range, index) => {
        if (index === (_data.ranges.length - 1) && range.count === 0) return;

        scores.push(range.count);
        labels.add((Math.round(range.start * 100) / 100).toFixed(2));
        labels.add((Math.round(range.end * 100) / 100).toFixed(2));
      });

      gpaDistribution = {
        chartData: {
          labels: Array.from(labels),
          scores
        },
        studentsCount: _data.student_count
      };
    } else gpaDistribution = null;

    if (data[1].status === 'fulfilled') {
      subjectCredits = data[1].value.data.subjects;
    } else subjectCredits = null;

    return { gpaDistribution, subjectCredits };
  };

  const { gpaDistribution, subjectCredits } = parseData();

  return (
    <>
      <div className="drawer-section transcripts-details-chart-section">
        <p className="section-title">Student GPA distribution</p>
        {gpaDistribution ?
          <>
            <p className="chart-title">Students in EOA cohort: {gpaDistribution.studentsCount}</p>
            <VerticalBarChart
              labels={gpaDistribution.chartData.labels}
              dataSets={[{data: gpaDistribution.chartData.scores, backgroundColor: darkGreenBarColor}]}
              displayDataLabels={true}
              xAxisTitle="GPA"
              yAxisTitle="Number of Students"
            />
          </>
          :
          <p>Error occured while fetching "Student GPA distribution" data!</p>
        }
      </div>
      <CustomDivider orientation="horizontal" />
      <div className="drawer-section">
        <p className="section-title">Average number of credits taken per subject area</p>
        <div className="average-data-section">
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          <div className="cell">
            <p className="cell-value no-margin">value</p>
            <span>subject area</span>
          </div>
          {subjectCredits ? 
            Object.entries(subjectCredits).map(([key, value]) => (
              <div key={key} className="cell" data-testid="subject-credits-info">
                <p className="cell-value no-margin">{value}</p>
                <span>{key}</span>
              </div>
            ))
            :
            <p>Error occured while fetching "Average number of credits taken per subject area" data!</p>
          }
        </div>
      </div>
    </>
  );
}

const TranscriptsSideDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptsSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getTranscriptsDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching transcript details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptsSideDrawerContentWithData;