import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

const CustomNode = ({id, data }: any) => {

  const isLeftHandleOnly = id === '4' || id === '5';
  const isRightHandleOnly = id === '1' || id === '2';
  
  return (
    <div style={{
      padding: '8px',
      borderRadius: '8px',
      border: '1px solid #000000',
      backgroundColor: '#FFFFFF',
      textAlign: 'center',
      fontSize: '12px'
    }}>
      {data.label}
      {!isLeftHandleOnly && (
        <Handle
          type="source"
          position={Position.Right}
        />
      )}
      {!isRightHandleOnly &&
          <Handle
            type="target"
            position={Position.Left} 
          />
      }
    </div>
  );
};
export default CustomNode;