import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);
interface VerticalBarChartProps {
  labels: string[];
  dataSets: {data: number[], backgroundColor: string}[]
  displayDataLabels: boolean;
  chartTitle?: string;
  customAspectRatio?: number;
  customBarPercentage?: number;
  customCategoryPercentage?: number;
  xAxisTitle?: string;
  yAxisTitle?: string;
  labelOffset?: number;
}

function VerticalBarChart({ 
  labels, 
  dataSets, 
  displayDataLabels, 
  chartTitle, 
  customAspectRatio, 
  customBarPercentage, 
  customCategoryPercentage,
  xAxisTitle,
  yAxisTitle,
  labelOffset
}: VerticalBarChartProps) {

  const _datasets = dataSets.map(dataSet => {
    return {
      data: dataSet.data,
      backgroundColor: dataSet.backgroundColor,
      borderRadius: 4,
      borderSkipped: false,
      barPercentage: customBarPercentage || 0.95,
      categoryPercentage: customCategoryPercentage || 1.0
    };
  });

  const data = {
    labels: labels,
    datasets: [..._datasets]
  };

  return (
    <Chart 
      plugins={[ChartDataLabels]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: customAspectRatio || 3 / 0.9,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: chartTitle || '',
            padding: {
              bottom: 20
            }
          },
          datalabels: {
            display: displayDataLabels,
            align: 'end',
            anchor: 'end',
            offset: -6,  
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: xAxisTitle,
              font: {
                size: 14,
              },
            },
            ticks: {
              labelOffset: labelOffset !== undefined ? labelOffset : -30,
            }
          },
          y: {
            title: {
              display: true, 
              text: yAxisTitle,
              font: {
                size: 14,
              },
            },
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                if (index % 2 === 0) {
                  return value;
                }
                return null;
              }
            },
          }
        }
      }}
      data={data}
    />
  );
}

export default VerticalBarChart;
