import { createContext } from 'react';
import FileTypesValues from '../interfaces/FileType';

interface UploadCardContextValue {
  file: File;
  fileName: string;
  fileType: FileTypesValues;
  fileIdToReplace: string | null;
  handleClose: () => void;
  handleUploadProgress: (progress: number) => void;
  fileMetadata: {
    [key: string]: string[];
  }
  triggerFileSelect: () => void;
}

const UploadCardContext = createContext<UploadCardContextValue>({
  file: new File([], 'placeholder'),
  fileName: 'placeholder file',
  fileType: 'STUDENT_ROSTER',
  fileIdToReplace: null,
  handleClose: () => { },
  handleUploadProgress: () => { },
  fileMetadata: {},
  triggerFileSelect: () => { }
});

export default UploadCardContext;
export type {
  UploadCardContextValue
};