import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, Navigate } from 'react-router-dom';
import Loader from './reusable/Loader';

const ProtectedRoute = () => {

  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <Loader fullScreen={true} />;

  if (isAuthenticated) return <Outlet />;

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
