import { createContext } from 'react';
import FileTypesValues from '../interfaces/FileType';

interface AuditLogTableContextValue {
  handleOpenAuditLog: (fileType: FileTypesValues | '') => void;
}

const AuditLogTableContext = createContext<AuditLogTableContextValue>({
  handleOpenAuditLog: () => {}
});

export default AuditLogTableContext;