import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactElement } from 'react';

//this svg does not look okay if I use it, will ask in the daily
//import customArrowIcon from '../assets/tooltip-arrow.svg';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({...props}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--text-black)',
    padding: props.arrow ? 'var(--constant-spacing-16)' : 'var(--constant-spacing-8)',
    borderRadius: props.arrow ? 'var(--border-radius-16)' : 'var(--border-radius-4)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--text-black)',
    // backgroundImage: `url(${customArrowIcon})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // transform: 'translate(-50%, -50%)',
    // color: 'transparent',
  }
}));

interface CustomTooltipProps {
    title: string | ReactElement<any, any> | undefined;
    placement: 'top' | 'right' | 'bottom' | 'left';
    arrow?: boolean;
    children: ReactElement<any, any>;
}

function CustomTooltip({title, placement, arrow, children}: CustomTooltipProps) {
  return (
    <StyledTooltip title={title} placement={placement} arrow={arrow}>
      {children}
    </StyledTooltip>
  );
}

export default CustomTooltip;