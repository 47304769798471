import { UploadRequirementsTable } from '../interfaces/UploadedFileDataInterface';
import SuccessIcon from './assets/data-upload-success.svg';
import WarningIcon from './assets/data-upload-warning-amber.svg';
import EmptyCheckBox from './assets/checkbox-outlined.svg';
import { AxiosResponse } from 'axios';
import { withData } from './reusable/withData';
import FileTypesValues from '../interfaces/FileType';
import { fileTypes } from '../constants';

import './SchoolsTable.css';

const uploadStatuses = [
  { status: 'Uploaded (no errors)', src: SuccessIcon, alt: 'check mark icon' },
  { status: 'Uploaded (with errors)', src: WarningIcon, alt: 'warning icon' },
  { status: 'Not yet uploaded', src: EmptyCheckBox, alt: 'empty check box icon' }
];

interface SchoolTableProps {
  data: UploadRequirementsTable;
  fileType: FileTypesValues;
}

export const STUDENT_DEMOGRAPHICS_COLUMNS_NAMES = ['Gender', 'Race/Ethnicity', 'Students with disability', 'English language learners', 'Low income'];

export function SchoolsTable({ data, fileType }: SchoolTableProps) {

  const getColumnNames = (schoolData: { [key: string]: boolean }) => {
    if (fileType === fileTypes.STUDENT_DEMOGRAPHICS) return STUDENT_DEMOGRAPHICS_COLUMNS_NAMES;

    return Object.keys(schoolData);
  };

  const prepareData = () => {
    let columnNames: string[] = [];
    let tableData: (string | boolean)[][] = [];

    Object.entries(data.schools).forEach(([schoolName, schoolData]) => {
      columnNames = getColumnNames(schoolData);
      tableData.push([schoolName, ...Object.values(schoolData)]);
    });

    return { columnNames, tableData };
  };

  const { columnNames, tableData } = prepareData();

  return (
    <>
      <div className="upload-statuses-container">
        {uploadStatuses.map(({ status, src, alt }, index) => (
          <div key={index} className="upload-status">
            <img src={src} alt={alt} className="icon-size" />
            <div>{status}</div>
          </div>
        ))}
      </div>
      <table className="table">
        <thead className="table-header">
          <tr className="table-row">
            <th>School</th>
            {columnNames.map((columnName, index) => (
              <th key={index}>
                {columnName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {tableData.map((dataRow, index) => (
            <tr key={index} className="table-row">
              {dataRow.map((dataCell, cellIndex) => {
                return (
                  <td
                    key={cellIndex}
                  >
                    {cellIndex === 0 ?
                      dataCell
                      :
                      <>
                        {dataCell === true && <img className="icon-size" src={SuccessIcon} alt="check mark icon" />}
                        {dataCell === false && <img className="icon-size" src={EmptyCheckBox} alt="empty check box icon" />}
                        {dataCell === 'error' && <img className="icon-size" src={WarningIcon} alt="warning icon" />}
                      </>
                    }
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

interface SchoolsTableWithDataProps {
  fetchData: (eoaId: string, access_token: string) => Promise<AxiosResponse<UploadRequirementsTable>>;
  fileType: FileTypesValues;
}
const SchoolsTableWithData = ({ fetchData, fileType }: SchoolsTableWithDataProps) => {

  const WrappedComponent = withData<{ fileType: FileTypesValues }>({
    WrappedComponent: SchoolsTable,
    fetchData: fetchData,
    errorMessage: 'Error occurred while fetching upload requirements data!'
  });

  return <WrappedComponent fileType={fileType} />;
};

export default SchoolsTableWithData;