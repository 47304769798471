import './Tag.css';

interface TagProps {
  type: 'pending' | 'progress' | 'completed';
  text: string;
  className: string;
}
function Tag({ type, text, className }: TagProps) {
  return (
    <div className={`tag ${type} ${className}`}>
      <p>{text}</p>
    </div>
  );
}

export default Tag;