import PageHeader from '../reusable/PageHeader';
import OverviewStepCard from './OverviewStepCard';
import DataUploadIcon from '../assets/nav-data-upload.svg';
import DataQcIcon from '../assets/nav-data-qc.svg';
import OverviewDistrictCard from './OverviewDistrictCard';
import { UPLOAD_SECTIONS_IDS, fileTypes } from '../../constants';

import './Overview.css';

function Overview() {
  return (
    <>
      <PageHeader title="Data overview" />
      <div className="main-content">
        <div className="overview-step-cards">
          <OverviewStepCard 
            cardIcon={DataUploadIcon} 
            stepNumber={1} 
            cardTitle="Upload Data" 
            cardDescription="Upload files that contain data, such as the student roster, course catalog, and student demographics, for each school." 
            buttonText="Data Upload"
            isActive={true}
            redirectTo="/data-upload"
          />
          <OverviewStepCard
            cardIcon={DataQcIcon}
            stepNumber={2}
            cardTitle="Data QC"
            cardDescription="After uploading your data, take a moment to review it and make sure it accurately represents your district."
            buttonText="Check data quality"
            isActive={false}
            redirectTo="/data-qc"
          />
        </div>
        <OverviewDistrictCard 
          districtName="Rhode Island School District"
          notStartedCategories={[
            { categoryName: 'Student Roster', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_ROSTER] }, 
            { categoryName: 'Course Catalog', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.COURSE_CATALOG] }
          ]}
          inProgressCategories={[
            { categoryName: 'Student Demographics', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_DEMOGRAPHICS] }, 
            { categoryName: 'Student Transcripts', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_TRANSCRIPTS] }
          ]}
          completedCategories={[
            { categoryName: 'Test Scores', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.TEST_SCORES] }, 
            { categoryName: 'CTE Pathways', anchorLink: UPLOAD_SECTIONS_IDS[fileTypes.CTE_PATHWAYS] }
          ]}
        />
      </div>
    </>
  );
}

export default Overview;