import Drawer from '@mui/material/Drawer';
import { ReactNode } from 'react';

interface SideDrawerOverlayProps {
  isOpen: boolean;
  handleClose: (open: boolean) => void;
  anchor: 'left' | 'top' | 'right' | 'bottom' | undefined;
  children:  ReactNode;
  className?: string;
}

function SideDrawerOverlay({isOpen, handleClose, anchor, children, className }: SideDrawerOverlayProps) {

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={handleClose}
    >
      <div className={className ? className : ''}>
        {children}
      </div>
    </Drawer>
  );
}

export default SideDrawerOverlay;