import './CteDetailsList.css';

interface CteDetailsListProps {
  cteCatalogsCount: number;
  cteEligibleCoursesCount: number;
  ctePathwaysCount: number;
}
function CteDetailsList({ cteCatalogsCount, cteEligibleCoursesCount, ctePathwaysCount }: CteDetailsListProps) {
  return (
    <div className="cte-details-list" data-testid="cte-details-container">
      <p className="first-column">{cteCatalogsCount}</p>
      <p>CTE catalogs</p>
      <p className="first-column">{cteEligibleCoursesCount}</p>
      <p>classes eligible for CTE pathways</p>
      <p className="first-column">{ctePathwaysCount}</p>
      <p>CTE pathways</p>
    </div>
  );
}

export default CteDetailsList;