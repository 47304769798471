import { useAuth0 } from '@auth0/auth0-react';
import { Dispatch, SetStateAction } from 'react';
import { Popover } from '@mui/material';
import CustomButton from '../reusable/CustomButton';

interface UserProfilePopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleIsProfileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const UserProfilePopover = ({
  anchorEl, 
  handleIsProfileMenuOpen, 
}: UserProfilePopoverProps) => {

  const { logout } = useAuth0();

  const handleClose = () => {
    handleIsProfileMenuOpen(false);
  };

  return <>
    <Popover
      open={true}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          sx: { width: anchorEl ? anchorEl.clientWidth : undefined }
        }
      }}
    >
      <CustomButton
        className="user-profile-popover-button"
        variant="clear" 
        buttonText="Settings" 
        buttonAction={() => {}}
        sx={{ 
          padding: 'var(--scaling-spacing-8)',
          display: 'block',
          textAlign: 'left',
          fontSize: 'var(--font-size-14)'
        }}
      />
      <CustomButton
        className="user-profile-popover-button"
        variant="clear" 
        buttonText="Sign Out" 
        buttonAction={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        sx={{
          padding: 'var(--scaling-spacing-8)',
          display: 'block',
          textAlign: 'left',
          fontSize: 'var(--font-size-14)'
        }}
      />
    </Popover>
  </>;
};

export default UserProfilePopover;