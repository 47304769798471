import CardContainer from './CardContainer';
import SideDrawer from './SideDrawer';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_LINKS_DATA } from '../../constants';

import './DataQcContentContainer.css';

interface DataQcContentContainerProps {
  drawerTitle: string;
  drawerContent: React.ReactNode
  cardTitle: string;
  cardContent: React.ReactNode;
  description: string;
}

const DataQcContentContainer = ({ cardContent, drawerContent, drawerTitle, cardTitle, description }: DataQcContentContainerProps) => {
  const navigate = useNavigate();

  return (
    <div className="data-qc-details-container">
      <div className="main-content">
        <CardContainer
          title={cardTitle}
          classes={['data-qc-card-container']}
        >
          {cardContent}
        </CardContainer>
      </div>
      <SideDrawer title={drawerTitle} description={description} classes={['data-qc-drawer-container']} handleClose={() => navigate(NAVIGATION_LINKS_DATA.DATA_QC.path)} isOpen={true}>
        {drawerContent}
      </SideDrawer>
    </div>
  );
};

export default DataQcContentContainer;