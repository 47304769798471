import { useState, useRef } from 'react';
import CustomModal from '../../reusable/CustomModal';
import selectionStepsMap, { SelectionStepKey, SELECTION_STEPS } from './selectionStepsConfigs';
import ReviewSelection from './ReviewSelection';
import useUploadCardData from '../../hooks/useUploadCardData';
import SelectionStepRenderer from './SelectionStepRenderer';

import './UploadPrompt.css';

interface SelectedData {
  [key: SelectionStepKey]: string[];
}

function UploadPrompt() {
  const { fileType, handleClose, fileMetadata } = useUploadCardData();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedData, setSelectedData] = useState<SelectedData>(fileMetadata);
  const cachedData = useRef<string[] | null>(null);

  const selectionSteps = selectionStepsMap[fileType].selectionSteps;
  const currentStep = selectionSteps[currentStepIndex];

  const handleSelection = (stepKey: string, isSelected: boolean, value: string[]) => {
    if (isSelected) {
      if (!selectedData[stepKey]) {
        selectedData[stepKey] = value;
      }
      else {
        let uniqueValues = value.filter(selectedValue => !selectedData[stepKey].includes(selectedValue));
        selectedData[stepKey] = selectedData[stepKey].concat(uniqueValues);
      }
    } else {
      selectedData[stepKey] = selectedData[stepKey].filter(selectedValue => !value.includes(selectedValue));
    }

    setSelectedData({ ...selectedData });
  };

  const handleEdit = (stepKey: string) => {
    let step = selectionSteps.findIndex(selectionStep => selectionStep.key === stepKey);

    setCurrentStepIndex(step);
  };

  const handleChangeStep = (stepIndex?: number) => {
    if (stepIndex) {
      setCurrentStepIndex(stepIndex);
      return;
    }

    setCurrentStepIndex(currentStepIndex + 1);
  };

  return (
    <CustomModal
      titleComponent={
        <div className="upload-prompt-header">
          {currentStep.key !== SELECTION_STEPS.ERROR.key ?
            // selectionSteps.length - 1, because the last step is for displaying errors
            <p className="no-margin steps-counter">Step {currentStepIndex + 1} of {selectionSteps.length - 1}</p>
            :
            null
          }
          <p className="no-margin step-name">
            {currentStep.title}
          </p>
        </div>
      }
      icon={false}
      isOpen={true}
      handleClose={handleClose}
      maxWidth="xs"
    >
      {currentStep.key === SELECTION_STEPS.REVIEW.key ?
        <ReviewSelection
          categoryName={selectionStepsMap[fileType].fileTypeName}
          selectionData={selectedData}
          handleEdit={handleEdit}
        />
        :
        <SelectionStepRenderer
          handleChangeStep={handleChangeStep}
          handleSelection={handleSelection}
          currentStep={currentStep}
          data={cachedData.current}
          fileType={fileType}
          selectedData={selectedData[currentStep.key] || []}
          updateCachedData={(data) => cachedData.current = data}
        />
      }
    </CustomModal>
  );
}

export default UploadPrompt;