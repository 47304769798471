import { USER_ROLES, NAVIGATION_LINKS_DATA } from '../../constants';
import CustomButton from '../reusable/CustomButton';
import CustomDivider from '../reusable/CustomDivider';
import { useLocation } from 'react-router-dom';
import OverviewIcon from '../assets/nav-overview.svg';
import DataUploadIcon from '../assets/nav-data-upload.svg';
import DataQcIcon from '../assets/nav-data-qc.svg';
import HelpIcon from '../assets/nav-help.svg';
import TranscriptsIcon from '../assets/nav-transcript.svg';
import useUserDetails from '../hooks/useUserDetails';
import SideBarNavigationLink from './SideBarNavigationLink';

import './SideBarNavigation.css';

const navLinks = [
  { 
    label: NAVIGATION_LINKS_DATA.OVERVIEW.label, 
    path: NAVIGATION_LINKS_DATA.OVERVIEW.path, 
    children: [], 
    isExpandable: false, 
    icon: OverviewIcon 
  }, 
  { 
    label: NAVIGATION_LINKS_DATA.DATA_UPLOAD.label, 
    path: NAVIGATION_LINKS_DATA.DATA_UPLOAD.path, 
    children: [], 
    isExpandable: false, 
    icon: DataUploadIcon 
  }, 
  {
    label: NAVIGATION_LINKS_DATA.DATA_QC.label,
    path: NAVIGATION_LINKS_DATA.DATA_QC.path,
    children: [
      {id: 1, label: 'Matching', path: '/data-qc/matching'},
      {id: 2, label: 'Test Scores', path: '/data-qc/test-scores'},
      {id: 3, label: 'Course Catalogs', path: '/data-qc/course-catalogs'},
      {id: 4, label: 'Transcripts', path: '/data-qc/transcripts'},
      {id: 5, label: 'CTE Courses', path: '/data-qc/cte-courses'}
    ], 
    isExpandable: true,
    icon: DataQcIcon
  },
  { 
    label: NAVIGATION_LINKS_DATA.TRANSCRIPTS.label, 
    path: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path, 
    children: [], 
    isExpandable: false, 
    icon: TranscriptsIcon 
  }
];
  
const helperLinks = [
  {label: 'Help', path: '/help', children: [], isExpandable: false, icon: HelpIcon}
];

interface SideBarNavigationContentProps {
    classes: string[];
    isOpen: boolean;
    buttonAction: () => void;
    src: string;
    handleCloseSideDrawer?: () => void;
}

function SideBarNavigationContent({classes, isOpen, buttonAction, src, handleCloseSideDrawer}: SideBarNavigationContentProps) {

  const { data: userDetails } = useUserDetails();

  const location = useLocation();
  const classNames = classes.join(' ');

  const filterNavLinksByUserRole = () => {
    const userRole = userDetails?.role;
    switch (userRole) {
    case USER_ROLES.DISTRICT_DATA_LEAD:
      return navLinks.filter(navLink => navLink.label !== NAVIGATION_LINKS_DATA.TRANSCRIPTS.label);
    case USER_ROLES.FACILITATOR:
      return navLinks.filter(navLink => navLink.label === NAVIGATION_LINKS_DATA.TRANSCRIPTS.label || navLink.label === NAVIGATION_LINKS_DATA.OVERVIEW.label);
    default:
      return navLinks;
    }
  };

  return (
    <nav 
      data-testid="sidebar" 
      className={`sidebar ${classNames}`}>
      <ul className="sidebar-items">
        <li className="navigation-link-container arrow-back">
          <CustomButton 
            variant="clear"
            buttonAction={buttonAction}
            sx={{ padding: '0', minWidth: '0' }}
          >
            <img alt="open/close navigation menu" src={src} />
          </CustomButton>
        </li>

        <li><CustomDivider size="small" orientation="horizontal" /></li>

        {filterNavLinksByUserRole().map(listItem => (
          <SideBarNavigationLink 
            key={listItem.label}
            path={listItem.path}
            icon={listItem.icon}
            label={listItem.label}
            children={listItem.children}
            isExpandable={listItem.isExpandable}
            currentPath={location.pathname}
            isNavigationClosed={!isOpen}
            handleCloseSideDrawer={handleCloseSideDrawer}
          />
        ))}

        <li><CustomDivider size="small" orientation="horizontal" /></li>

        {helperLinks.map(listItem => (
          <SideBarNavigationLink 
            key={listItem.label}
            path={listItem.path}
            icon={listItem.icon}
            label={listItem.label}
            children={listItem.children}
            isExpandable={listItem.isExpandable}
            currentPath={location.pathname}
            isNavigationClosed={!isOpen}
            handleCloseSideDrawer={handleCloseSideDrawer}
          />
        ))}
      </ul>
    </nav>
  );
}

export default SideBarNavigationContent;