import { useState } from 'react';
import CustomPagination from '../reusable/CustomPagination';
import CustomTable from '../reusable/CustomTable';
import { TableHeaderData } from '../reusable/CustomTable';
import { getAuditLog } from '../../services/transcriptsService';
import { AuditLogData } from '../../interfaces/UploadedFileDataInterface';
import { AUDIT_LOG_ITEMS_PER_PAGE, fileTypesLabels } from '../../constants';
import SideDrawer from '../data qc/SideDrawer';
import { withData } from '../reusable/withData';
import FileTypesValues from '../../interfaces/FileType';

import './AuditLogModalTable.css';

const renderTableCellAuditLogData = (value: any) => {
  const date = new Date(value);
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  const formattedDate = dateFormatter.format(date);

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  const formattedTime = timeFormatter.format(date);
  return `${formattedDate} ${formattedTime}`;
};

const tableHeaderData: TableHeaderData<AuditLogData>[] = [
  {
    key: 'user_name',
    label: 'User Name'
  },
  {
    key: 'action',
    label: 'Action'
  },
  {
    key: 'file_name',
    label: 'Name of File'
  },
  {
    key: 'timestamp',
    label: 'Time/Date',
    icons: ['sort']
  }
];

interface AuditLogModalTableProps {
  handleCloseModal: () => void;
  fileType: FileTypesValues | '';
}

function AuditLogModalTable({ handleCloseModal, fileType }: AuditLogModalTableProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const updateCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const TableData = withData<TableDataProps>({
    WrappedComponent: AuditLogTableData,
    fetchData: (eoaId: string, access_token: string) => getAuditLog(eoaId, access_token, currentPage, AUDIT_LOG_ITEMS_PER_PAGE),
    errorMessage: 'Error occurred while fetching audit log data!'
  });

  return (
    <SideDrawer 
      title={`Audit log - ${fileTypesLabels[fileType as FileTypesValues]}`} 
      isOpen={true} 
      handleClose={handleCloseModal} 
      classes={['audit-log-side-drawer']}
    >
      <TableData triggerDataFetch={updateCurrentPage} currentPage={currentPage} />
    </SideDrawer>
  );
}

interface AuditLogTableDataProps {
  data: { items: AuditLogData[], total: number, pages: number };
  triggerDataFetch: (newPage: number) => void;
  currentPage: number;
}

type TableDataProps = Omit<AuditLogTableDataProps, 'data'>;

export const AuditLogTableData = ({ data, triggerDataFetch, currentPage }: AuditLogTableDataProps) => {
  data.items.map(item => item.timestamp = renderTableCellAuditLogData(item.timestamp));

  return (
    <>
      <CustomTable<AuditLogData> tableHeaderData={tableHeaderData} tableBodyData={data.items} />
      <CustomPagination
        currentPage={currentPage}
        totalItems={data.total}
        pages={data.pages}
        pageSize={AUDIT_LOG_ITEMS_PER_PAGE}
        handlePageChange={triggerDataFetch} 
      />
    </>
  );
};

export default AuditLogModalTable;